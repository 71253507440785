import React from 'react'
import styled from 'styled-components'

import { ColMixin, WinnersList } from './elements'

const StartCol = styled.div`
  ${ColMixin}
`

const StartColWinnersList = styled(StartCol)`
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const StartWinnersList = styled(WinnersList)``

const StartWinnersListItem = ({ winners, stage, order }) => {
  const allWinners = stage !== 0 ? winners[stage] : winners
  let winnersLength = allWinners.winnersList.length
  let winnersDivide = winnersLength % 2
  let winnersDivider = 0

  if (winnersDivide === 0) {
    winnersDivider = winnersLength / 2 - 1
  } else {
    winnersDivider = Math.trunc(winnersLength / 2)
  }
  return (
    <StartColWinnersList>
      <StartWinnersList>
        {allWinners.winnersList.map((winner, index) => {
          let condition =
            order === 1 ? index <= winnersDivider : index > winnersDivider
          if (condition) {
            return <li key={index}>{winner}</li>
          }
        })}
      </StartWinnersList>
    </StartColWinnersList>
  )
}

export default StartWinnersListItem
