import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Start from '../components/start'
import AwardWinners from '../components/award-winners'
import Contacts from '../components/contacts'
import Footer from '../components/footer'

const IndexPage = (props) => (
  <Layout
    page="zwyciezcy"
    title="Zwycięzcy - Schaeffler RALLYEXPERTS"
    description="Rejestruj regularnie punkty bonusowe REPXPERT, WYGRYWAJ CO TYDZIEŃ INNĄ NAGRODĘ, ZBIERZ CAŁĄ KOLEKCJĘ i zmierz się z innymi w rajdowej rywalizacji w Subaru Impreza."
  >
    <Header page="zwyciezcy" location={props.location} />
    <Start />
    <AwardWinners page="zwyciezcy" />
    <Contacts />
    <Footer />
  </Layout>
)

export default IndexPage
