import React from 'react'
import styled from 'styled-components'
import { Textfit } from 'react-textfit'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  HeaderTitleWithLines,
  WinnersList,
} from './elements'

const AwardWinnerWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 15px;
  z-index: 100;
  @media (min-width: 1200px) and (max-width: 1599px) {
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (max-width: 575px) {
  }
`

const AwardWinnerContainer = styled.div`
  ${ContainerMixin}
`

const AwardWinnerRow = styled.div`
  ${RowMixin}
`

const AwardWinnerCol = styled.div`
  ${ColMixin}
`

const AwardWinnerColHeader = styled(AwardWinnerCol)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 100px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-bottom: 20px;
  }
`

const AwardWinnerHeaderTitle = styled(HeaderTitleWithLines)`
  .textWrapper {
    & > span {
      & > span {
        color: ${(props) => props.theme.textColor};
      }
    }
  }
`

const AwardWinnerRowList = styled(AwardWinnerRow)`
  ${PaddingsContainerMixin}
  padding-top: 80px;

  @media (min-width: 1200px) {
    padding-left: 9.885%;
    padding-right: 9.885%;
  }

  @media (max-width: 575px) {
    padding-top: 40px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 0 0 15px 15px;
    width: 100%;
  }
`

const AwardWinnerColList = styled(AwardWinnerCol)`
  @media (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AwardWinnersList = styled(WinnersList)``

const AwardWinnerRowCongratulation = styled(AwardWinnerRow)``

const AwardWinnerColCongratulation = styled(AwardWinnerCol)`
  flex: 0 0 100%;
  max-width: 100%;
`

const AwardWinnerCongratulationTitle = styled.p`
  color: #ff1e39;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.3;
  padding-left: 1%;
  padding-right: 1%;
  margin-top: 120px;
  margin-bottom: 160px;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 100px;
    margin-bottom: 140px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 80px;
    margin-bottom: 120px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 60px;
    margin-bottom: 100px;
  }
  @media (max-width: 575px) {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`

const AwardWinner = (props) => {
  const winners = [
    {
      category: 'Grupa A',
      winners: [
        'PHU MKA ANDRZEJ ANTKIEWICZ, DĄBIE',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, ĆWIKÓW',
        'RAFAŁ PISKORZ MECHANIKA POJAZDOWA, KRAKÓW',
        'MECHANIKA POJAZDOWA EM-CAR, ZAGNAŃSK',
        'AUTO GUCIO PAWEŁ GĄSIOREK, ŁĘGOWO',
        'AUTO-SERWIS ROMUALD SZWOCH, TUCHOLA',
        'AUTO KLINIKA NOSARZEWSKI, STARA IWICZNA',
        'ELEKTROMECHANIKA POJAZDOWA ARTUR KRZYWAŃSKI, CZĘSTOCHOWA',
        'AUGUSTYNIAK SERWIS, PRUSZKÓW',
        'MECHANIKA SAMOCHODOWA MARCIN JANICKI, DĘBICA',
        ' V. 8. ALEKSANDER MALISZEWSKI, GŁOGÓW',
        'MECHANIKA POJAZDOWA KRZYSZTOF HUTEK, LIMANOWA',
        'DAWID SZYMAŃSKI SYNEKTUNE, ANTONINÓW',
        'COLDMARK, ŁOSICE',
        'M&M AUTO SERWIS, ZGIERZ',
        'AUTO-LAK, ALWERNIA',
        'PHU PAWEŁ KOZŁOWSKI, WOJSŁAWICE',
      ]
    },
    {
      category: 'Grupa B',
      winners: [
        'INTERTRANSPORT SP. Z O.O., RADZYŃ PODLASKI',
        'AUTOMAX JACEK BAGIŃSKI, KOLOBRZEG',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'AD SERWIS AGROBUD, MYŚLENICE',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE',
        'USŁUGI MOTORYZACYJNE MARCIN BOŁOZ, PIWNICZNA-ZDRÓJ',
        'FHU SZIK , RZESZÓW',
        'AUTO MIŚ, SZCZECIN',
        'AUTO NAPRAWA ŁUKASZ SZMIGIEL, MRĄGOWO',
        'AUTO KOSIK, GDAŃSK',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'AUTO SERWIS JAROSŁAW SYREK, MODLNICA',
        'MECHANIKA POJAZDOWA HUDECKI, ANDRYCHÓW',
        'AUTO CENTRUM, RADZIONKÓW',
        'PIOTROWSKA ELŻBIETA , CHOJNA',
        'JARO-FILTR, RADZYMIN',
        'ZUH MOTO-BAZAR, RADOM',
        'KOPEX, KIELCE',
        'PUH POLMOZBYT SO, BOLESŁAWIEC',
        'CENTRUM SAMOCHODOWE TOMASZ MROCZKOWSKI , GORZÓW WIELKOPOLSKI ',
        'MECHANIKA POJAZDOWA ZATOR EDWARD, ŻAGAŃ',
        'PRZEMYSŁAW PIETRZAK, BIAŁA',
        'MAKSMAN GARAGE, STARE CZARNOWO ',
        'PUH ADAKO MECHANIKA POJAZDOWA BOHDAN KOCISZ, BIAŁYSTOK',
        'AUTO-ŚMIECH MECHANIKA POJAZDOWA, KRZEMIENIEWO',
        'MAGIC FAST LISIK MARIUSZ, OLEŚNICA',
        'EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA',
        'AUTO MECHANIKA, FRAMPOL',
        'GMT CARS SERVICE, SŁUPSK',
        'AUTODAK S. C., MYSŁOWICE',
        'ZAKŁAD MECHANIKI POJAZDOWEJ ANTONI SZUBZDA, WASILKÓW',
        'AUTO-KLINIKA, KROSNO',
      ]
    },
    {
      category: 'Grupa C',
      winners: [
        'PUH AUTO-NAPRAWA-OSTROWSKI MARIUSZ OSTROWSKI, BYDGOSZCZ',
        'PUNKT NAPRAWY SAMOCHODÓW MARIUSZ GÓRNY, ŁOBEZ',
        'AUTO-TOP S.C., KROSNO',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
      ]
    },
    {
      category: 'Grupa D',
      winners: [
        'EXTRUCKT SP. Z O.O, WARSZAWA',
        'ELEKTROMECHANIKA KAMIL GRINHOLC, KĘBŁOWO',
        'BIG JOHNY GARAGE, SKOWARCZ',
        'FIRMA HANDLOWO - USŁUGOWA ŁUKASZ BUCIAK, BIELANY WROCŁAWSKIE',
        'DACAR, STARGARD',
      ]
    },
  ]

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
  }

  return (
    <AwardWinnerWrapper id="awards">
      <AwardWinnerContainer>
        <AwardWinnerRow>
          <AwardWinnerColHeader>
            <AwardWinnerHeaderTitle page={props.page}>
              <span className="textWrapper">
                <span className="line first left" />
                <span className="line middle left" />
                <span className="line last left" />
                <span>
                  <span>Nagroda</span>
                  <span>główna</span>
                  <span />
                  {/*<span>
                    Na wyjątkowe szkolenie z Tomkiem Kucharem
                    <br /> zakwalifikowali się:
                  </span>*/}
                </span>
                <span className="line first right" />
                <span className="line middle right" />
                <span className="line last right" />
              </span>
            </AwardWinnerHeaderTitle>
          </AwardWinnerColHeader>
        </AwardWinnerRow>

        {winners.map((winner, index) => {
          return <AwardWinnerRowList>
            <h4>{winner.category.toUpperCase()}</h4>

            {sliceIntoChunks(winner.winners, Math.ceil(winner.winners.length / 2)).map((sliceWinners, sliceIdx) => <AwardWinnerColList>
              <AwardWinnersList>
                {sliceWinners.map(sW => <li>{sW.toUpperCase()}</li>)}
              </AwardWinnersList>
            </AwardWinnerColList>)}

          </AwardWinnerRowList>
        })}

        {/*<AwardWinnerRowCongratulation>
          <AwardWinnerColCongratulation>
            <AwardWinnerCongratulationTitle>
              <Textfit mode="single" max={200}>
                wszystkim zwycięzcom gratulujemy
                <br /> Do zobaczenia na torze!
              </Textfit>
            </AwardWinnerCongratulationTitle>
          </AwardWinnerColCongratulation>
        </AwardWinnerRowCongratulation>*/}
      </AwardWinnerContainer>
    </AwardWinnerWrapper>
  )
}

export default AwardWinner
