import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'
import { Textfit } from 'react-textfit'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  PaddingsContainerMixin,
} from './elements'
import StartWinnersListItem from './start-winners-list-item'

import startBG from '../images/start-bg.svg'
import startCallBG from '../images/start-call-bg.svg'

const StartWrapper = styled.div`
  position: relative;
  background-image: url(${startCallBG});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  padding-bottom: 30px;
  margin-bottom: 150px;
  z-index: 80;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: 120px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 100px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 80px;
  }
  @media (max-width: 575px) {
    margin-bottom: 50px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    height: 15000px;
    background-image: url(${startBG});
    /* background-repeat: no-repeat; */
    background-position: top center;
    background-size: contain;
    pointer-events: none;
    z-index: -10;
  }
`

const StartContainer = styled.div`
  ${ContainerMixin}
`

const StartRow = styled.div`
  ${RowMixin}
`

const StartCol = styled.div`
  ${ColMixin}
`

const StartColPoints = styled(StartCol)`
  ${PaddingsContainerMixin}
  padding-left: 0!important;
`

const StartPointsWrapper = styled.div`
  position: relative;
`

const StartPoint = styled(LinkScroll)`
  position: absolute;
  top: 6.5%;
  left: 5%;
  display: block;
  width: 12%;
  height: 9.2%;
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  ${(props) =>
    props.stage === 1 &&
    `
    top: 8.5%;
    left: 58%;
  `}
  ${(props) =>
    props.stage === 2 &&
    `
    top: 13%;
    left: 72.2%;
  `}
  ${(props) =>
    props.stage === 3 &&
    `
    top: 33.9%;
    left: 58%;
  `}
  ${(props) =>
    props.stage === 4 &&
    `
    top: 33.8%;
    left: 32%;
  `}
  ${(props) =>
    props.stage === 5 &&
    `
    top: 38.2%;
    left: 17.5%;
  `}
  ${(props) =>
    props.stage === 6 &&
    `
    top: 58.7%;
    left: 27.4%;
  `}
  ${(props) =>
    props.stage === 7 &&
    `
    top: 58.8%;
    left: 53.5%;
  `}
  ${(props) =>
    props.stage === 8 &&
    `
    top: 63.4%;
    left: 71.1%;
  `}
  ${(props) =>
    props.stage === 9 &&
    `
    top: 83.6%;
    left: 42.4%;
    width: 15%;
    height: 16.75%;
  `}
  /* ${(props) =>
    props.stage === 10 &&
    `
    top: 62.8%;
    left: 80.4%;
  `} */
  /* ${(props) =>
    props.stage === 11 &&
    `
    top: 83.6%;
    left: 47.4%;
    width: 15%;
    height: 16.75%;
  `} */
`

const StartRowlWinnersList = styled(StartRow)`
  ${PaddingsContainerMixin}
  padding-top: 80px;
  @media (min-width: 1200px) {
    padding-left: 9.885%;
    padding-right: 9.885%;
  }
  @media (max-width: 575px) {
    padding-top: 40px;
  }
`

const StartColWinnersTitleList = styled(StartCol)`
  flex: 0 0 100%;
  max-width: 100%;
`

const StartWinnersTitleList = styled.h2`
  color: #fff;
  font-size: 90px;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  line-height: 0.7;
  margin-bottom: 140px;
  @media (min-width: 1200px) and (max-width: 1440px) {
    line-height: 0.5;
    margin-bottom: 120px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    line-height: 0.5;
    margin-bottom: 100px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    line-height: 0.45;
    margin-bottom: 80px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    line-height: 0.4;
    margin-bottom: 70px;
  }
  @media (max-width: 575px) {
    line-height: 0.3;
    margin-bottom: 50px;
  }
  span {
    display: block;
    &:first-child {
      padding-left: 42%;
      padding-right: 42%;
      @media (max-width: 575px) {
        padding-left: 36%;
        padding-right: 36%;
      }
    }
    &:last-child {
      padding-left: 8%;
      padding-right: 8%;
      @media (max-width: 575px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`

const StartColWinnersTabs = styled(StartCol)`
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;
`

const StartColWinnersTab = styled(StartCol)`
  flex: 0 0 20%;
  max-width: 20%;
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 15px;

  display: ${props => props.stage === 0 ? `none` : `block`};

  @media (min-width: 1600px) {
    font-size: 24px;
  }
  @media (min-width: 1380px) and (max-width: 1599px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1379px) {
    font-size: 18px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    flex: 0 0 25%;
    max-width: 25%;
    font-size: 22px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    font-size: 19px;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
  @media (max-width: 340px) {
    font-size: 12px;
  }
  span {
    position: relative;
    display: block;
    font-weight: 700;
    opacity: ${(props) => (props.stage === props.currentStage ? `1` : `0.5`)};
    transition: opacity 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 1;
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -8px;
      display: block;
      height: 1px;
      background-color: #fff;
      opacity: ${(props) => (props.stage === props.currentStage ? `1` : `0.5`)};
    }
  }
`

const StartColWinnersHeader = styled(StartCol)`
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
  padding-bottom: 20px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 26px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
  @media (max-width: 340px) {
    font-size: 18px;
  }
`

const StartRowCall = styled(StartRow)`
  ${PaddingsContainerMixin}
  padding-top: 80px;
  padding-bottom: 30px;
`

const StartColCall = styled(StartCol)`
  flex: 0 0 100%;
  max-width: 100%;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.15;
  p {
    padding-left: 6.5%;
    padding-right: 6.5%;
    &:last-child {
      color: #fff142;
    }
  }
`

const MotivationP = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @media (max-width: 992px) {
    font-size: 20px;
  }
`

const Start = () => {
  const data = useStaticQuery(graphql`
    query {
      StartPoints: file(relativePath: { eq: "start-points.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [stage, setStage] = useState(0)

  const setStagePoint = (stage) => {
    setStage(stage)
  }

  const winners = [
    {
      dataTab: 'Ranking',
      winnersLists: [
        {
          header: 'Zwycięzcy w czterech OS/etapach',
          winnersList: [
            'AD SERWIS AGROBUD, MYŚLENICE',
            'AUTOMAX JACEK BAGIŃSKI, KOLOBRZEG',
            'AUTOSET, DĄBROWA',
            'AUTO SERWIS ZIELIŃSKI, MALBORK',
            'PUH AUTO-NAPRAWA-OSTROWSKI MARIUSZ OSTROWSKI, BYDGOSZCZ',
            'CENTRUM SAMOCHODOWE TOMASZ MROCZKOWSKI , GORZÓW WIELKOPOLSKI ',
            'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
            'M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, LEŚNA PODLASKA',
            'INTERTRANSPORT SP. Z O.O., RADZYŃ PODLASKI',
            'DACAR, STARGARD',
            'FIRMA HANDLOWO - USŁUGOWA ŁUKASZ BUCIAK, BIELANY WROCŁAWSKIE',
            'EXTRUCKT SP. Z O.O, WARSZAWA',
            'ELEKTROMECHANIKA KAMIL GRINHOLC, KĘBŁOWO',
            'PUNKT NAPRAWY SAMOCHODÓW MARIUSZ GÓRNY, ŁOBEZ',
            'PHU MKA ANDRZEJ ANTKIEWICZ, DĄBIE',
            'BIG JOHNY GARAGE, SKOWARCZ',
          ],
        },
        {
          header: 'Zwycięzcy w trzech OS/etapach',
          winnersList: [
            'PUH POLMOZBYT SO, BOLESŁAWIEC',
            'RAFAŁ PISKORZ MECHANIKA POJAZDOWA, KRAKÓW',
            'AUTO-TOP S.C., KROSNO',
            'KOŁODYŃSKI GARAŻ, STRZEGOM',
            'FIRMA USŁUGOWA ARKADIUSZ CISKOWSKI, MRĄGOWO',
            'MECHANIKA POJAZDOWA HUDECKI, ANDRYCHÓW',
            'DAWID SZYMAŃSKI SYNEKTUNE, ANTONINÓW',
            'MECHANIKA SAMOCHODOWA, PRUDNIK',
            'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
            'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
            'TOM-CARS, KIELCE',
            'PHU WOLNIK MIROSŁAWA WOLNIK, RĘBIECHOWO',
            'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE',
            'EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ',
            'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, ĆWIKÓW',
            'MECHANIKA POJAZDOWA ZATOR EDWARD, ŻAGAŃ',
            'AUTO KOSIK, GDAŃSK',
            'KOPEX, KIELCE',
            'AUTO NAPRAWA ŁUKASZ SZMIGIEL, MRĄGOWO',
            'AUTO SERWIS RAFAŁ RAK, BYTOM',
            'KAROL CZARNECKI, RADOM',
            'USŁUGI TRANSPORTOWE JAN CIAK, MRĄGOWO',
            'AUTO SERWIS STRZYKUŁY, STRZYKUŁY',
            'GEARBOX , NOWOGARD',
            'AUTO SERWIS JAROSŁAW SYREK, MODLNICA',
            'KT SERWIS KONRAD TUSIEWICZ, MARKI',
          ],
        },
        {
          header: 'Zwycięzcy w dwóch OS/etapach',
          winnersList: [
            'F.H.U MOTO-MAR, WARSZAWA',
            'AUTO-CZĘŚCI TOMASZ SUDER, WIELICZKA',
            'AUTO SERWIS MICHAŁ PODGÓRNY, CZELADŹ',
            'AUTO-RAF SOBERA RAFAŁ, PĄTNÓW',
            'USŁUGI MOTORYZACYJNE MARCIN BOŁOZ, PIWNICZNA-ZDRÓJ',
            'AUTOPITSTOP, ŚWIDNICA',
            'ZAKŁAD MECHANIKI POJAZDOWEJ ANTONI SZUBZDA, WASILKÓW',
            'BOSCH SERVICE GRZEŚKOWIAK - PIETRZAK, RAWICZ',
            'AUTO-SERWIS ROMUALD SZWOCH, TUCHOLA',
            'V-MAX BARTOSZ SŁAWIANOWSKI, RUMIA',
            'NAPRAWA POJAZDÓW SAMOCHODOWYCH JAROSŁAW LUBAS, GRYFINO',
            'QUBEC SERWIS PAWEŁ WITKIEWICZ, OLSZTYN',
            'AUTO-ŚMIECH MECHANIKA POJAZDOWA, KRZEMIENIEWO',
            'PUH ADAKO MECHANIKA POJAZDOWA BOHDAN KOCISZ, BIAŁYSTOK',
            'GP AUTO GARAGE PRZEMYSŁAW GĄCIARZ, GRYFINO',
            'OMT MATEUSZ TOKARZ, TARNÓW',
            'ADI-MAX-AUTO ADRIAN  KOMYCZ, NOWY SĄCZ',
            'TRUCK-SERVICE , SZCZYTNO ',
            'GMT CARS SERVICE, SŁUPSK',
            'TIRPARTNER, ŁUKÓW',
            'TM AUTO TOMASZ MARCINIUK, MIĘDZYRZEC PODLASKI',
            'PIOTR PENCZEK CAR CLUB WARSZTAT SAMOCHODOWY, CHEŁM ŚLĄSKI ',
            'AUTOMANIA, DRZYCIM',
            'JUL-CAR, WŁOCŁAWEK ',
            'PRZEMYSŁAW PIETRZAK, BIAŁA',
            'EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA',
            'GA-CAR WARSZTAT SAMOCHODOWY SZCZYREK GRZEGORZ, ROPCZYCE',
            'ELEKTROMECHANIKA POJAZDOWA , PIECE ',
            'EURO-CARS BOGDAN GOŁĄB, SZAMOTUŁY',
            'FIRMA HANDLOWO-USŁUGOWA PRODUKCYJNA  DB-AUTO DOMINIK BUŁAŚ, KRASNE',
            'FHU SZIK , RZESZÓW',
            'JANUS AUTO CENTRUM, JĘDRZEJÓW',
            'FHU TOMASZ NAKONECZNY, GOLENIÓW',
            'PIO-MAR AUTO NAPRAWA PIOTR STĘPIEŃ MARCIN ROGALA, BEŁCHATÓW',
            'ANDRZEJ ULIASZ, MIEJSCE PIASTOWE',
            'ARIS-AUTO, STARGARD SZCZECIŃSKI',
            'M&M AUTO SERWIS, ZGIERZ',
            'JAN WOŹNIAK, JANOWIEC WIELKOPOLSKI',
            'AUTO MIŚ, SZCZECIN',
            'MECHANIKA POJAZDOWA KRZYSZTOF KOWALEWSKI, BIAŁYSTOK',
            'MECHANIKA POJAZDOWA KONRAD GOŁĘBIEWSKI, BOROWA GÓRA',
            '"SEBO", PIEŃSK',
            'FHU BEMAR, SZADEK',
            'MIREX - SZANDECKI I MARCZUK SP.J., BIAŁA PODLASKA',
            'AUGUSTYNIAK SERWIS, PRUSZKÓW',
            'MOTO-FAN S.C., PRZEMYŚL',
            'P.U.H. TAD-ROM, MIKOŁÓW',
            'MMK-MOTO, MYSLENICE',
            'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
            'KAMPOL1 RAFAŁ LOFEK, BYDGOSZCZ',
            'OLICAR 2 MONIKA MISZTAL, BEDZIN',
            'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
            'CENTRUM OBSŁUGI POJAZDÓW S.C. SŁAWOMIRA KUBACKA,ARKADIUSZ KUBACKI, LUBAWKA',
            'AUTO KLINIKA NOSARZEWSKI, STARA IWICZNA',
            'AUTO MECHANIK SERWIS TOMASZ DUDEK, ROGÓW',
            'ZAKŁAD MECHANIKI POJAZDOWEJ WOJCIECH PIEKARSKI, KALISZ',
            'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
            'JARWIT JAROSŁAW SŁOWIŃSKI, DĘBNO',
            'MECHANIKA POJAZDOWA EM-CAR, ZAGNAŃSK',
            'STEBLEJ CAR SERVICE, STRONIE ŚLĄSKIE',
            'AUTO GUCIO PAWEŁ GĄSIOREK, ŁĘGOWO',
            'PPH BETROM ROMAN BETLEJEWSKI, OSTRÓW MAZOWIECKA',
            'ZYGI-AUTO, STARY WĘGLINIEC',
            'MECHANIKA POJAZDOWA JAROSŁAW CIARKOWSKI, NOWY DWÓR GDAŃSKI',
            'MAGIC FAST LISIK MARIUSZ, OLEŚNICA',
            'CAROCK, DOBROMIERZ',
            'MECHANIA POJAZDOWA, MIRZEC',
            'AUTO SERWIS ANDRZEJ ZBĄDZKI, OSIEK',
            'COLDMARK, ŁOSICE',
            'AUTO-TECH RZEPKA WALDEMAR, RYCHWAŁ',
            'ZAKŁAD MECHANIKI POJAZDOWEJ TOMALA AUTO SERVICE TOMASZ BANASZEWSKI, SŁUPSK',
            'FAST-SERWS DAMIAN PIEKARZ MECHANIKA POJAZDOWA, TARNOBRZEG',
            'MECHANIKA POJAZDOWA ROBERT GRZESIAK, LUTUTÓW',
            'PUH WYCHODZKI MACIEJ, PRZEWORNO',
          ],
        },
      ],
    },
    {
      dataTab: '13.09-19.09',
      winnersList: [
        'AUTO STANISŁAW KANAREK, DRAWSKO POMORSKIE',
        'AD SERWIS AGROBUD, MYŚLENICE',
        'AKCESORIA SAMOCHODOWE, NAŁĘCZÓW',
        'AL-AUTO S.C., RASZYN',
        'ANDRZEJ ULIASZ, MIEJSCE PIASTOWE',
        'AUTO CRAFT HELENA I ZBIGNIEW WOWK SP.J., STALOWA WOLA',
        'AUTO KLINIKA NOSARZEWSKI, STARA IWICZNA',
        'AUTO KOSIK, GDAŃSK',
        'AUTO MIŚ, SZCZECIN',
        'AUTO NAPRAWA, LIPIE GÓRY',
        'AUTO NAPRAWA LIPNIEWSKI PIOTR, CHEŁMNO',
        'AUTO NAPRAWA MAREK RUDNIK, RZECZENICA',
        'AUTO NAPRAWA TADEUSZ ŚMIAROWSKI, ŁOMŻA',
        'AUTO PARTNER RADOSŁAW MRÓZ, GARWOLIN',
        'AUTO PARTNER S.C., LIBIĄŻ',
        'AUTO SERWIS, PIEŃSK',
        'AUTO SERWIS, RABKA ZDROJ',
        'AUTO SERWIS JAROSŁAW SYREK, MODLNICA',
        'AUTO SERWIS MICHAŁ PODGÓRNY, CZELADŹ',
        'AUTO SERWIS RAFAŁ RAK, BYTOM',
        'AUTO SERWIS STRZYKUŁY, STRZYKUŁY',
        'AUTO SERWIS ZIELIŃSKI, MALBORK',
        'AUTO SZASER, WARSZAWA',
        'AUTO WULF, SKOCZÓW',
        'AUTO-COMPLEX, MORAWICA',
        'AUTO-CZĘŚCI TOMASZ SUDER, WIELICZKA',
        'AUTODAK S. C., MYSŁOWICE',
        'AUTO-DIAGNOZA GRZEGORZ BRONCHARD, TORUŃ',
        'AUTOHANS, RUDNIK NAD SANEM',
        'AUTOMANIA, DRZYCIM',
        'AUTOMAX JACEK BAGIŃSKI, KOLOBRZEG',
        'AUTOMOBILKLUB PODLASKI, BIAŁYSTOK',
        'AUTO-OBSŁUGA ROMAN GÓRNOWICZ, CZERSK',
        'AUTOPANA SZYMON NAPIERAŁA, ROGOŹNO',
        'AUTOPITSTOP, ŚWIDNICA',
        'AUTOPRACOWNIA MECHANIKA POJAZDOWA, REDA',
        'AUTO-RAF SOBERA RAFAŁ, PĄTNÓW',
        'AUTO-SERWIS, STARY SACZ',
        'AUTOSERWIS MARIUSZ MAGDZIARZ, OŚWIĘCIM - RAJSKO',
        'AUTO-SERWIS ROMUALD SZWOCH, TUCHOLA',
        'AUTOSERWIS SZYMON CHOWANIEC, ŁAZY',
        'AUTOSET, DĄBROWA',
        'AUTO-ŚMIECH MECHANIKA POJAZDOWA, KRZEMIENIEWO',
        'AUTO-TOP S.C., KROSNO',
        'AUTOWIJA, GDANSK',
        'AZ SERVICE MECHANIKA POJAZDOWA, TCZEW',
        'BAZYL TRANS, SUWAŁKI',
        'BENDIKS JACEK MEHRING, WEJHEROWO',
        'BES CAR, ŁÓDŹ',
        'BIG AUTO DARIUSZ MAJKOWSKI, MAKÓW MAZOWIECKI',
        'BIG JOHNY GARAGE, SKOWARCZ',
        'BLACHARSTWO I MECHANIKA SAMOCHODOWA BOGUSŁAW KACHEL, STRYSZAWA',
        'BOSCH SERVICE GRZEŚKOWIAK - PIETRZAK, RAWICZ',
        'BZYK GARAGE, SOBOLEW',
        'CAPITOL INVEST COMPANY SP. Z O.O., SULECHÓW',
        'CARO SP.J A.WYSOCKI Z.WYSOCKI, TCZEW',
        'CAROS-SERVICE WOJCIECH GŁOWATY, WROCŁAW',
        'CENTRUM MOTORYZACJI, PISZ',
        'CENTRUM OBSŁUGI POJAZDÓW S.C. SŁAWOMIRA KUBACKA,ARKADIUSZ KUBACKI, LUBAWKA',
        'CENTRUM SAMOCHODOWE TOMASZ MROCZKOWSKI, GORZÓW WIELKOPOLSKI',
        'COLDMARK, ŁOSICE',
        'CUSTOM GARAGE CG-PIOTR NOWACKI, RADZANOWO',
        'DACAR, STARGARD',
        'DAN-V.A.G. DANIEL MRZYGŁÓD, NOWA WIEŚ LĘBORSKA',
        'DARMARCARS MARCIN OLSZEWSKI, NASIELSK',
        'D-M-KA MARIUSZ KARPIŃSKI, LUBANIE',
        'EKG MOTORS EWA SZUTA, MISZEWKO',
        'ELDORADO KRZYSZTOF PIERGIES, NOWOGRODZIEC',
        'ELEKTROMECHANIKA KAMIL GRINHOLC, KĘBŁOWO',
        'ELEKTROMECHANIKA SAMOCHODOWA MARIUSZ DĄBROWSKI, KOSZALIN',
        'EURO-CARS BOGDAN GOŁĄB, SZAMOTUŁY',
        'EXTRUCKT SP. Z O.O, WARSZAWA',
        'F.H.U MOTO-MAR, WARSZAWA',
        'F.H.U. "SACER" S.C. W.ŻUKOWSKI, I.ŻUKOWSKA, T.ŻUKOWSKI, ŁĘKNICA',
        'FAST-SERWS DAMIAN PIEKARZ MECHANIKA POJAZDOWA, TARNOBRZEG',
        'FHU EWMAR AUTO NAPRAWA MARCIN SZCZUDLOWSKI, MSZANA DOLNA',
        'FHU TOMASZ NAKONECZNY, GOLENIÓW',
        'FIRMA HANDLOWO - USŁUGOWA ŁUKASZ BUCIAK, BIELANY WROCŁAWSKIE',
        'FIRMA HANDLOWO-USŁUGOWA PRODUKCYJNA  DB-AUTO DOMINIK BUŁAŚ, KRASNE',
        'FIRMA MARCAR MAŁGORZATA WOLAK, ZAKLICZYN',
        'FIRMA USŁUGOWA ARKADIUSZ CISKOWSKI, MRĄGOWO',
        'FISCHER-SERVICE, BOCHNIA',
        'GMT CARS SERVICE, SŁUPSK',
        'GP AUTO GARAGE PRZEMYSŁAW GĄCIARZ, GRYFINO',
        'HS AUTO, WRONKI',
        'INTERTRANSPORT SP. Z O.O., RADZYŃ PODLASKI',
        'JAN WOŹNIAK, JANOWIEC WIELKOPOLSKI',
        'JANULEWICZ AUTO SERWIS, SEJNY',
        'JAPAN SERVICE, WARSZAWA',
        'JARO-FILTR, RADZYMIN',
        'JARWIT JAROSŁAW SŁOWIŃSKI, DĘBNO',
        'KAMPOL1 RAFAŁ LOFEK, BYDGOSZCZ',
        'KAROL CZARNECKI, RADOM',
        'KLAG MOTORS, ŁĄCKO',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'KOPEX, KIELCE',
        'KRISCAR KRZYSZTOF JELIŃSKI, SULĘCZYNO',
        'KTJ, RZESZÓW',
        'LAWETA DAWID PŁACZEK, RYBNIK',
        'LESZEK GŁADYŚ MECHANIKA POJAZDOWA, ŻNIN',
        'M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, LEŚNA PODLASKA',
        'M.G.M.MOTO-SERVICE S.C., BIRCZA',
        'MAGIC FAST LISIK MARIUSZ, OLEŚNICA',
        'MAG-SERWIS JACEK WÓDKIEWICZ, JEDNOROŻEC',
        'MECHANIKA I WULKANIZACJA TOMASZ NOWAK, SAROŻYN',
        'MECHANIKA POJAZDOWA, NIWISKA',
        'MECHANIKA POJAZDOWA, MICHÓW',
        'MECHANIKA POJAZDOWA, PŁOŃSK,SKARŻYN',
        'MECHANIKA POJAZDOWA BLACHARSTWO, LAKIERNICTWO KRZYSZTOF TARASIUK, DRELÓW',
        'MECHANIKA POJAZDOWA GRZEGORZ ZALEWSKI, STRZAŁKOWO',
        'MECHANIKA POJAZDOWA HUDECKI, ANDRYCHÓW',
        'MECHANIKA POJAZDOWA JACEK WŁODARSKI, RASZYN',
        'MECHANIKA POJAZDOWA KRZYSZTOF GRUCA, CHLEWICE',
        'MECHANIKA POJAZDOWA KRZYSZTOF KOWALEWSKI, BIAŁYSTOK',
        'MECHANIKA POJAZDOWA LUDA HUBERT, ŁĄCZNIK',
        'MECHANIKA POJAZDOWA MACIEJ BLICHARZ, TUSZYMA',
        'MECHANIKA POJAZDOWA RAFAŁ HERBIK, NOWE SKALMIERZYCE',
        'MECHANIKA POJAZDOWA ROBERT GRZESIAK, LUTUTÓW',
        'MECHANIKA POJAZDOWA SAMOCHODÓW OSOBOWYCH I CIĘŻAROWYCH SYLWE, RZEPIN',
        'MECHANIKA POJAZDOWA STANISŁAW POMIERSKI, LINIEWO',
        'MECHANIKA POJAZDOWA TERESA PYDYN, OPOLE',
        'MECHANIKA POJAZDOWA ZATOR EDWARD, ŻAGAŃ',
        'MECHANIKA POJAZDOWA ZBIGNIEW TOBIASZ, CHRZANÓW',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'MECHANIKA SAMOCHODOWA, PRUDNIK',
        'MECHANIKA SAMOCHODOWA BARTOSZ HERTMAN, OŁAWA',
        'MIK SERWIS, WARSZAWA',
        'MIREX - SZANDECKI I MARCZUK SP.J., BIAŁA PODLASKA',
        'MIROSŁAW SUFRYD, KROTOSZYN',
        'MMK-MOTO, MYSLENICE',
        'MOBILNE USŁUGI MECHANICZNE DANUTA KOSTUCH, NOWA WIEŚ LĘBORSKA',
        'MOBILNY SERWIS AGRO-TIR ADAM DANIŁOWICZ, SZYPLISZKI',
        'MOTO MC MICHAŁ CIASTOŃ, KRAKÓW-NOWA HUTA',
        'MOTOBOX JAKUB BAGIŃSKI, ŚWIEBODZIN',
        'MOTOCOMBI, RADZISZÓW',
        'MOTOMANIA DAMIAN SIELWOŃCZUK, BIAŁYSTOK',
        'MOTO-MARKO MAREK MILCZAREK, ŁOWICZ',
        'MTS AUTO SERWIS, PODDĘBICE',
        'NAM SERVICE, LUBNIEWICE',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH JAROSŁAW LUBAS, GRYFINO',
        'NAPRAWA SAMOCHODÓW, SARNAKI',
        'NSCAR NATAN SIRKO, KROSNO',
        'OMT MATEUSZ TOKARZ, TARNÓW',
        'OPLEX, ŁÓDŹ',
        'PAN SAMOCHODZIK, LUBIN',
        'PERFECT AUTO FILIP KOZŁOWSKI, BIAŁYSTOK',
        'PHU ETOLL SP J  J.TATARSKI Z.TATARSKI A.JAWORSKI, LEWIN BRZESKI',
        'PHU GTECH, KIELCE',
        'PHU MEKS, ALEKSANDRÓW ŁÓDZKI',
        'PHU MKA ANDRZEJ ANTKIEWICZ, DĄBIE',
        'PIO-MAR AUTO NAPRAWA PIOTR STĘPIEŃ MARCIN ROGALA, BEŁCHATÓW',
        'PIOTR PENCZEK CAR CLUB WARSZTAT SAMOCHODOWY, CHEŁM ŚLĄSKI',
        'PIOTR TALAROWSKI, PACZKÓW',
        'PODLASKI ZAKŁAD MOTORYZACYJNY SP. Z O.O., BIAŁA PODLASKA',
        'PPHU KAMARO KATARZYNA PODSIADŁA, ZWOLEŃ',
        'PPHU MAX, PYZDRY',
        'PPHU TOMASZ HAPUNOWICZ, BIAŁYSTOK',
        'PROCARS, LIMANOWA',
        'PROFIXCAR SERWIS, PIASECZNO',
        'PUH ADAKO MECHANIKA POJAZDOWA BOHDAN KOCISZ, BIAŁYSTOK',
        'PUH AUTO-NAPRAWA-OSTROWSKI MARIUSZ OSTROWSKI, BYDGOSZCZ',
        'PUH POLMOZBYT SO, BOLESŁAWIEC',
        'PUNKT NAPRAWY SAMOCHODÓW MARIUSZ GÓRNY, ŁOBEZ',
        'QUBEC SERWIS PAWEŁ WITKIEWICZ, OLSZTYN',
        'R4 MOTOR SPORT, ZŁOTORYJA',
        'RAFAŁ PISKORZ MECHANIKA POJAZDOWA, KRAKÓW',
        'ROBERT WITCZAK PHU MOTORYZACJA, PŁOTY',
        'ROL-MOT, WIERZBICA',
        'S.M AUTO SERWIS, NOWE LIPINY',
        "SERWI U 'HUDEGO', KIELCE",
        'SERWIS "SEBO", PIEŃSK',
        'SERWIS SAMOCHODOWY LUCJAN KOWAL, ŁUBNICE',
        "SKLEP MOTORYZACYJNY 'MARKOS' PIOTR BUCZKO, BARWICE",
        'SKLEP MOTORYZACYJNY U SIDORA, LUBARTÓW',
        'SKLEP MOTORYZACYJNY VTEC, RYMANÓW',
        'STEBLEJ CAR SERVICE, STRONIE ŚLĄSKIE',
        'STRZAŁKA MECHANIKA POJAZDOWA, STRZELIN',
        'SYNCAR & DIAGNOSTYKA DAWID SZYMAŃSKI, ADAMOWIZNA',
        'TIRPARTNER, ŁUKÓW',
        'TOMCAR S.C., KOKOSZKOWY',
        'TOP AUTO SERVICE, RASZYN',
        'TRUCK-SERVICE, SZCZYTNO',
        'TS AUTO, WARSZAWA',
        'USŁUGI SERWISOWE, GDAŃSK',
        'USŁUGI W ZAKRESIE MECHANIKI SAMOCHODOWEJ I LAKIERNICTWA, CZERWIONKA-LESZCZYNY',
        'V V AUTO, GRODZISK MAZOWIECKI',
        'VITOMAR, BIAŁOGARD',
        'V-MAX BARTOSZ SŁAWIANOWSKI, RUMIA',
        'W.P.H. AUTO-CENTRUM SWARZĘDZ, SWARZĘDZ',
        'WARSZTAT MECHANICZNO ŚLUSARSKI TOMCAR TOMASZ MARCHLEWSKI, KRĘPA',
        'WARSZTAT SAMOCHODOWY PAWEŁ SOBCZYK, SIEMIANOWICE ŚLASKIE',
        'WIST     KRZYSZTOF DRĄCZKOWSKI, HRUBIESZÓW',
        'WIT CAR GRZEGORZ WITUS, OSTRÓDA',
        'WOJCIECH ZDRODOWSKI, CHOROSZCZ',
        'WULKANIZACJA WITEX, SMĘTOWO CHMIELEŃSKIE',
        'WULKANIZACJA X-GUM ANDRZEJ BOCHENKO, ZAMOŚĆ',
        'ZAKŁAD MECHANIKI POJAZDOWEJ ANTONI SZUBZDA, WASILKÓW',
        'ZAKŁAD MECHANIKI POJAZDOWEJ WOJCIECH PIEKARSKI, KALISZ',
        'ZAKŁAD USŁUG MOTORYZACYJNYCH I HANDLU STEFAN JAREK, ORZESZE',
        'ZUH AUTOS BAJ SPÓŁKA JAWNA, DREZDENKO',
      ],
    },
    {
      dataTab: '20.09-26.09',
      winnersList: [
        "AD SERWIS AGROBUD, MYŚLENICE",
        "AD.AUTO ARTUR ABROWSKI, Olszewo Borki",
        "Adi-Max-Auto Adrian  komycz, nowy sącz",
        "AK Motors, Pruszków",
        "Al-Trans Holowanie Elektromechanika Leszek Popowicz, Radom",
        "ASMOT  IRENA I KAZIMIERZ ANIELAK FELIKS SZABLA, KRASNYSTAW",
        "ATI.Autoserwis Adam Hławiczka , Bażanowice ",
        "Auto -Kowal, Górowo Iławeckie",
        "AUTO AGRO COMPLEX, OPOLE LUBELSKIE",
        "AUTO CENTRUM, Jaworzno",
        "Auto Części Dariusz Marciniak, Koło",
        "AUTO KERI, Wilga",
        "AUTO KOSIK, GDAŃSK",
        "Auto Kropka, Zielona Góra",
        "Auto Market Ireneusz Rosiak, Turek",
        "AUTO MAX Zdzisław Trotzki, Orle",
        "AUTO MECHANIK SERWIS Tomasz Dudek, Rogów",
        "AUTO NAPRAWA, Zalesie",
        "AUTO NAPRAWA ANDRZEJ WIERCIŃSKI, ZAMBRÓW",
        "Auto Naprawa Łukasz Szmigiel, Mrągowo",
        "AUTO NAPRAWA WOLSKI MACIEJ, SŁUPSK",
        "Auto serwis, Dubienka",
        "AUTO SERWIS DANIEL GONTATRCZYK, MILANÓWEK",
        "Auto Serwis Gołębiowski Ewa Gołębiowska, Zwierzyniec",
        "Auto Serwis Idzikowski, Kielce",
        "Auto Serwis Jarosław Syrek, Modlnica",
        "Auto Serwis Marcin Pabin, Międzyrzecz",
        "AUTO SERWIS MICHAŁ PODGÓRNY, CZELADŹ",
        "Auto serwis Posłowski, wrocław",
        "AUTO SERWIS RAFAŁ RAK, Bytom",
        "AUTO SERWIS ROMAN KUREK, SUCHA BESKIDZKA",
        "Auto Serwis Seby - Sebastian Telus, Opoczno",
        "Auto Serwis Strzykuły, Strzykuły",
        "AUTO SERWIS ZIELIŃSKI, Malbork",
        "AUTO-CENTRUM ADAM WOŹNIAK, Gorlice",
        "AUTO-CZĘŚCI \"ŁUCZYCKI\", ŚRODA ŚLĄSKA",
        "auto-kala, Przywory",
        "AUTO-KLINIKA, Krosno",
        "AUTO-MARAS, Kcynia",
        "AUTO-MARK P.P.H.U. KONRAD MŁUDZIK, ŁÓDŹ",
        "Auto-Mechanika Jakub Maniecki, Jelenia Góra",
        "AUTO-NAPRAWA Tomasz Mroczkowski, OLSZEWO-BORKI",
        "AUTO-PREMIUM Mateusz Ciumaszko, Brzeziny",
        "Auto-Service, Mstów",
        "Auto-Service Mieczysław Żukowski, Koszalin",
        "AUTO-SERWIS, Łuków",
        "AUTO-SERWIS ROMUALD SZWOCH, Tuchola",
        "auto-śmiech mechanika pojazdowa, krzemieniewo",
        "AUTO-TOP S.C., KROSNO",
        "AutoABC, Białystok",
        "autocentrum adam szymborski, katowice",
        "AUTOCHOS, GUBIN",
        "AUTODOKTOOR SERWIS JAKUB PILTZ, WROCŁAW",
        "AUTOELEKTRO Łysoń Ireneusz, Andrychów",
        "AutoJack Jacek Smoleński, Sosnowiec",
        "AUTOMANIA Mechanika Samochodowa Adam Bartkowski , Kokoszkowy",
        "AUTOMAX Jacek Bagiński, kolobrzeg",
        "Autonaprawa Przemysław Małaj, Lublin",
        "Autopol Siof Witold, Siof Grażyna, Oleśnica",
        "Autoset, Dąbrowa",
        "Autostarter, OLSZOWA",
        "Big Johny garage, Skowarcz",
        "BOLTI CAR PIŁAT PAWEŁ, ŁAŃCUT",
        "bosch service grześkowiak - pietrzak, RAWICZ",
        "CAR STUDIO  NORBERT SKROBEK, Większyce",
        "CaRock, Dobromierz",
        "CARS MAREK ŁOPAT, LIPKA",
        "Carserwis, Bielsk Podlaski",
        "CBC Cracow Business Corporation Sp. z o.o., Kraków",
        "Centrum Samochodowe Tomasz Mroczkowski , Gorzów Wielkopolski ",
        "DaCar, Stargard",
        "DAFMOBILE, MIRKÓW",
        "DAR-CAR DARIUSZ DASZCZYK, JAROCIN",
        "DERASERWIS, MYSŁOWICE",
        "Eko Auto, Krosno",
        "elektro-mechanika pojazdowa wOJCIESZYŃSKI MARCIN, CHĘCINY",
        "Elektromechanika, Mirsk",
        "Elektromechanika Kamil Grinholc, Kębłowo",
        "elektromechanika mechanika pojazdowa andrzej wolski, Miechucino",
        "Elektromechanika pojazdowa , Piece ",
        "ELEKTROMECHANIKA POJAZDOWA MICHAŁ NOWACKI, OSTRZESZÓW",
        "ELEKTRONIKA POJAZDOWA ADAM JOWIK, MIĘDZYRZEC PODLASKI",
        "EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA",
        "EURO-CARS Bogdan Gołąb, SZAMOTUŁY",
        "EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ",
        "Extruckt sp. z o.o, warszawa",
        "F. H. U. \"ADMAX\" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, Ćwików",
        "F.H.U MOTO-MAR, Warszawa",
        "F.H.U. Auto Polak Urszula Pisarczyk-Polak, Gorlice",
        "FHU AUTO-MOTO GRZEGORZ KUCZERA, CIESZYN",
        "FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA",
        "FHU KARSSON , Wolsztyn",
        "Fhu Sławomir Wróbel , Szczecin",
        "FHU SZIK , Rzeszów",
        "Firma Handlowo - Usługowa Łukasz Buciak, Bielany Wrocławskie",
        "firma usługowa Arkadiusz Ciskowski, Mrągowo",
        "firma zielpol Jerzy Zielonka, Piotrków Trybunalski",
        "GA-CAR WARSZTAT SAMOCHODOWY SZCZYREK GRZEGORZ, ROPCZYCE",
        "GearBox , Nowogard",
        "GP Auto Garage Przemysław Gąciarz, Gryfino",
        "Gumar, Olsztyn ",
        "gumpol polmozbyt, zambrów",
        "HAMELUSZ, ZAMOŚĆ",
        "Handel ogumieniem akcesoriami smochodowymi Liwska Sabina, Legionowo",
        "HLP EUROTRANSPORT SERWIS, WROCŁAW",
        "Hofi Car, Zabełków",
        "Intertransport Sp. z o.o., Radzyń Podlaski",
        "IT SERWIS Cierocki Albecki, Reda",
        "JANUS AUTO CENTRUM, Jędrzejów",
        "Karol Czarnecki, Radom",
        "KOŁODYŃSKI GARAŻ, STRZEGOM",
        "KT Serwis Konrad Tusiewicz, Marki",
        "M-Point, Biłgoraj",
        "M.A.S. Serwis Kamil Krzysztofik, Starogard Gdanski",
        "M&M Auto Serwis, Zgierz",
        "M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, leśna podlaska",
        "MACIEJ DOLATA CZĘŚCI I AKCESORIA SAMOCHODOWE, POZNAŃ",
        "Maksman Garage, Stare Czarnowo ",
        "Mariusz Milczarczyk, Magnuszew",
        "MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE",
        "MECHANIKA POJAZDOWA, TUCHOLA",
        "mechanika pojazdowa, wieliczka",
        "Mechanika Pojazdowa  Kazimierz Myszk, Sulęczyno",
        "MECHANIKA POJAZDOWA ANDRZEJ-CARS ANDRZEJ PODRAZA, Jodłówka Tuchowska ",
        "Mechanika Pojazdowa Dziubaczka, Ciężkowice",
        "Mechanika pojazdowa Jacek Dziedzic, Widawa",
        "Mechanika pojazdowa Jan Owsiński, Olesnica",
        "Mechanika Pojazdowa Jarosław Ciarkowski, Nowy Dwór Gdański",
        "Mechanika Pojazdowa Marek Stryganek, Hajnówka",
        "Mechanika Pojazdowa Radosław Krakus, Żelazków",
        "MECHANIKA POJAZDOWA SEBASTIAN KORZENIOWSKI, PRASZKA",
        "Mechanika Pojazdowa Sławomir Wolak, Czechowice-Dziedzice",
        "Mechanika Pojazdowa Tomasz Domański, Bełchatów",
        "mechanika samochodowa, prudnik",
        "MM F.H.U. MICHAŁ STĘPNIAK, ZGIERZ",
        "MOBIL AUTOSERWIS , Orzesze",
        "Mobilny Auto Serwis , Opole Lubelskie",
        "Moto-Car, Kępno",
        "MOTO-FAN S.C., PRZEMYŚL",
        "Moto-Partner, Szczecin",
        "NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC",
        "OMT MATEUSZ TOKARZ, TARNÓW",
        "P.H.U. Zakład Mechaniki Pojazdowej Jerzy Fidura, Grajewo",
        "P.P.U.H Auto-Moto-Szlif, Jawor",
        "PHU MKA Andrzej Antkiewicz, Dąbie",
        "PHU Wolnik Mirosława Wolnik, Rębiechowo",
        "Pio-Mar Auto Naprawa Piotr Stępień Marcin Rogala, Bełchatów",
        "Piotrowska Elżbieta , CHOJNA",
        "PITER SERWIS , KORCZYNA ",
        "PPHU MIKOŁAJCZYK, Pogorzela",
        "Przemysław Pietrzak, Biała",
        "PUH ADAKO Mechanika Pojazdowa Bohdan Kocisz, Białystok",
        "PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski, Bydgoszcz",
        "PUH POLMOZBYT SO, BOLESŁAWIEC",
        "PUH Ratajczak, Gryfice",
        "Punkt naprawy samochodów Mariusz Górny, Łobez",
        "QUBEC SERWIS Paweł Witkiewicz, Olsztyn",
        "R.S.MOTO, ŚRODA wielkopolska",
        "RAD-TRANS, Bydgoszcz",
        "RAF-CAR Rafał Kłeczek, głogów małopolski",
        "Rafał Piskorz Mechanika pojazdowa, Kraków",
        "RAMI, ZAGÓRZ",
        "Renikol Świątkowski Krzysztof, Gostynin",
        "REWEX, Legnica ",
        "serwis k, Łomża",
        "Serwis pogwarancyjny, Stalowa Wola",
        "SKODAR-AUTO, Olsztyn",
        "spaw-metal, goworowo",
        "Spec Serwis, Gdańsk",
        "Szczyt-Car, Chlewiska",
        "TBM AUTO, RABKA ZDRÓJ",
        "TEK TRANS, CZERNICHÓW",
        "TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE",
        "TM AUTO Tomasz Marciniuk, Międzyrzec Podlaski",
        "TOM-CAR, Bukowiec",
        "TOM-CARS, Kielce",
        "Tomasz Szymenderski, Kostrzyn nad Odrą",
        "TOMCAR-SERVICE TOMASZ STRĘGIEL, Łódź",
        "Towarowy Transport Drogowy Jan Lenartowicz, Busko Zdrój",
        "TRUCK-SERVICE , Szczytno ",
        "Usługi Elektromechaniczne Grzegorz Worek, Otmuchów",
        "Usługi Motoryzacyjne Marcin Bołoz, Piwniczna-Zdrój",
        "Usługi Transportowe Jan Ciak, Mrągowo",
        "Usługi Transportowe Ryszard Sobański, Główczyce",
        "Usługi-Mechanika Pojazdowa Urbański Mirosław, Występ",
        "V-max Bartosz Sławianowski, Rumia",
        "V8-semka Daignostyka Samochodowa Łukasz Garbacz, Łabunie",
        "VPE-SERVICE PIOTR EKMAN, GDAŃSK",
        "VRSERVICE Wojciech Borysiuk, Choroszcz",
        "Warsztat Mechaniki Pojazdowej Łukasz Szymkowiak, Środa wlkp",
        "WARSZTAT SAMOCHODOWY  BARTŁOMIEJ LISEK, Wrocław",
        "wocar, Grodzisk Mazowiecki",
        "WOLCAR AUTO, LUBLIN",
        "Z.H.U SAWCAR, Międzyrzec Podlaski",
        "Zakład Handlowo Usługowy Tranzbet Grzegorz Łoś, Boleszkowice",
        "zakład mechaniczny wądołowski piotr, Zawady",
        "ZAKŁAD MECHANIKI POJAZDOWEJ D.JACKOWSKI, KOBYLIN",
        "Zakład mechaniki pojazdowej i wulkanizacji, Dzianisz",
        "ZAKŁAD USŁUGOWO MECHANICZNY & DERBA DANIEL, przemyśl",
        "ZUH Auto Service, Zwoleń",
      ]
    },
    {
      dataTab: '27.09-03.10',
      winnersList: [
        '"AUTO MOTOR" MATEUSZ PIOSIK, RAKONIEWICE',
        'A-Ż Auto serwis, Ostrołęka',
        'AD SERWIS AGROBUD, MYŚLENICE',
        'AD SERWIS GRZEGORZ BROŚ, Kocmyrzów',
        'Adi-Max-Auto Adrian  komycz, nowy sącz',
        'Agromech, Kleszczele',
        'andrzej miszczuk auto inżynieria, Kalinówka',
        'Andrzej Uliasz, Miejsce Piastowe',
        'ARIS-AUTO, STARGARD SZCZECIŃSKI',
        'ASZ NAPRAWA POJAZDÓW, Sawin',
        'AUGUSTYNIAK SERWIS, Pruszków',
        'Auto "Traffic" Józef Nakonieczny, Garbów',
        'AUTO CENTRUM, RADZIONKÓW',
        'AUTO GUCIO Paweł Gąsiorek, ŁĘGOWO',
        'Auto Handel usługi Krzyszczyk Joachim, Wieszowa',
        'Auto klinika Karol Żmijewski, Warszawa',
        'Auto Mechanik Piotr Staworko, Wasilków',
        'Auto naprawa blacharstwo i mechanika pojazdowa, Ziebicey',
        'Auto Naprawa Dariusz Masłowski, Płock',
        'Auto Naprawa Dzidek, Jastrowie',
        'Auto Naprawa Łukasz Szmigiel, Mrągowo',
        'Auto Naprawa Marcin Witkowski, Nasielsk',
        'AUTO POMOC Paweł Kluziak, Gdańsk',
        'AUTO SERWIS DRAGON MONIKA KIERSZTYN, ZAMOŚĆ',
        'Auto Serwis jarosław pacholski, goleniów',
        'AUTO SERWIS ŁUKASZ NOWAK, KOCMYRZOW',
        'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
        'AUTO SERWIS ZIELIŃSKI, Malbork',
        'Auto Sowa  Łukasz Sowa, Gródek nad Dunajcem',
        'Auto SPA Marta Bogucka, POKRÓWKA',
        'auto usługi, mędrzechów',
        'AUTO-CZĘŚCI TOMASZ SUDER, WIELICZKA',
        'AUTO-LAK, ALWERNIA',
        'AUTO-MAG Paweł Porzeżyński, GRODKÓW',
        'AUTO-RAF SOBERA RAFAŁ, PĄTNÓW',
        'AUTO-SERVICE ZIELIŃSKI, Góra Kalwaria',
        'AUTO-SERWIS DEPTUCH, JAWOR',
        'AUTO-TECH RZEPKA WALDEMAR, RYCHWAŁ',
        'AUTO-TECH S.C., SMOLEC',
        'AUTO-TOP S.C., KROSNO',
        'Auto-Waś Jan Waś, Warszawa',
        'AUTOELEKTRONIKA-PIOMECH, Bielsk Podlaski',
        'Autoklima serwis klimatyzacji samochodowej Grzegorz Różański, Iwierzyce ',
        'Automania, Drzycim',
        'AUTOMAX Jacek Bagiński, kolobrzeg',
        'AUTOMOBIL SERWIS DIAGNOSTYKA I NAPRAWA SAMOCHODÓW, Jarosław',
        'AUTOPITSTOP, Świdnica',
        'Autoserwis, Wiśniowa',
        'Autoset, Dąbrowa',
        'Big Johny garage, Skowarcz',
        'CarPart, Mszczonów',
        'Centrum Samochodowe Tomasz Mroczkowski , Gorzów Wielkopolski ',
        'Centrum Serwis S.c. J.Lachowicz P.Pilecki, Szczecin',
        'DaCar, Stargard',
        'DAKRO SP. Z O.O., LUBLIN',
        'Dariusz Bajerski Naprawa Pojazdów Samochodowych, Brzeźnica',
        'Data Auto sp.j., TYCHY',
        'Dawid Szymański SynekTune, Antoninów',
        'DIAGNOSTYKA U MIETKA, NOWOGRODZIEC',
        'ELEKTROMECHANIKA I DIAGNOSTYKA POJAZDOWA  ARTUR BALCERAK, KUTNO',
        'Elektromechanika Kamil Grinholc, Kębłowo',
        'Elektromechanika Pojazdowa, Siedlce',
        'Elektromechanika Pojazdowa, Kozy',
        'Elektromechanika pojazdowa , Piece ',
        'Elektromechanika Pojazdowa Artur Krzywański, CZęstochowa',
        'elektromechanika pojazdowa Marek Ozga, Zarzecze',
        'Elektromechanika Pojazdowa Piotr Kopczyński, Police',
        'EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA',
        'EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ',
        'Extruckt sp. z o.o, warszawa',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, Ćwików',
        'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
        'F.H.KOMA AUTO-SERWIS MIROSŁAW KOZA, RADZYŃ PODLASKI',
        'F.H.U DAN-MOT DANUTA PEKA, Zblewo',
        'F.H.U RECAR Tomasz Rekus, Nakło Śląskie',
        'Fabryka Mocy, Szczecin',
        'FAMIKO-BUD SP. Z O.O., LUBLIN',
        'FBU PIOTR PEJKO, PILZNO',
        'FHU AUTO-CZĘŚĆI BERNADETA SZELIGA, SĘDZISZÓW MŁP.',
        'FHU AUTOLUK, Gumniska',
        'FHU BEMAR, SZADEK',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
        'FHU SZIK , Rzeszów',
        'FHU Tomasz Nakoneczny, Goleniów',
        'Firma Handlowo - Usługowa Łukasz Buciak, Bielany Wrocławskie',
        'FIRMA HANDLOWO USŁUGOWA EDEN RUDZIS REMIGIUSZ, WĘGORZEWO',
        'FIRMA HANDLOWO-USŁUGOWA PRODUKCYJNA  DB-AUTO DOMINIK BUŁAŚ, krasne',
        'FIRMA MOTORYZACYJNA BUNDA, BRANIEWO',
        'Firma Usługowa "ST SERWIS", Ropa',
        'firma usługowa Arkadiusz Ciskowski, Mrągowo',
        'Firma Usługowo Handlowa Somad, Radymno',
        'GA-CAR WARSZTAT SAMOCHODOWY SZCZYREK GRZEGORZ, ROPCZYCE',
        'GARAGE 27, Świdnica',
        'GearBox , Nowogard',
        'GMT CARS SERVICE, Słupsk',
        'Hemi Wojciech Bartosz, Stronie',
        'HIENKA ANGELIKA STANEK, KRAKOW',
        'Intertransport Sp. z o.o., Radzyń Podlaski',
        'J&P Piotr Boksa, Siechnice',
        'JANUS AUTO CENTRUM, Jędrzejów',
        'Jul-car, Włocławek ',
        'KAMAR-MOT SP. Z O.O. , Radomsko',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'KOPEX, KIELCE',
        'KT Serwis Konrad Tusiewicz, Marki',
        'KZ Auto Naprawa - Serwis Klimatyzacji Karol Ziemann, Puck',
        'M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, leśna podlaska',
        'MAKSYMILIAN SPIŻEWSKI, POŁAJEWO',
        'MAR-AUTO Marek Grabczewski, Skepe',
        'Marecki auto naprawa, Wejherowo',
        'Marzena Kunicka, NIDZICA',
        'Mat-Mech Mechanika Pojazdowa Mateusz Batog, Olesnica',
        'Mechania pojazdowa, Mirzec',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'mechanika pojazdowa, ciechanów',
        'Mechanika Pojazdowa, PINCZYN',
        'Mechanika pojazdowa, Pabianice',
        'Mechanika Pojazdowa, Proszowice',
        'Mechanika pojazdowa Dawid Ozimek , STARY DZIKÓW',
        'Mechanika Pojazdowa EM-CAR, Zagnańsk',
        'MECHANIKA POJAZDOWA HUDECKI, ANDRYCHÓW',
        'MECHANIKA POJAZDOWA JACEK HLEBIK, SANTOK',
        'Mechanika Pojazdowa Konrad Gołębiewski, Borowa Góra',
        'Mechanika pojazdowa Krzysztof Kościołowski, Kruszwica',
        'Mechanika Pojazdowa Mariusz Kędziora, Głowno',
        'MECHANIKA POJAZDOWA MAX KRZYSZTOF URBANIAK, SZCZECIN',
        'Mechanika Pojazdowa Pomoc Drogowa Jarosław Przybysz, Garwolin',
        'Mechanika Pojazdowa Pomoc Drogowa Jarosław Zamroziewicz, Kożuchów',
        'MECHANIKA POJAZDOWA SYLWESTER JON, OSŁONIN',
        'Mechanika Pojazdowa Zator Edward, Żagań',
        'MOTO-MIX JADWIGA CYKOWASK, KŁOBUCK',
        'Naprawa i konserwacja Tomasz Kasprzak , Wolsztyn',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'Naprawa Pojazdów Samochodowych, Ujście',
        'Naprawa Pojazdów Samochodowych Jarosław Lubas, Gryfino',
        'NOSTER-PŁONKA SPÓŁKA JAWNA, Oświęcim',
        'NOWIX Piotr Nowak, Dobre',
        'Olicar 2 Monika Misztal, Bedzin',
        'P. H. U. DYMEX  ZBIGNIEW MIELEWCZYK, KOŚCIERZYNA',
        'P.H. NODI, Bogatynia',
        'P.H.U. - Wipexim - Wiesław Pstrucha, Chrzanów',
        'P.H.U. "AD-Cars", Zwoleń',
        'P.H.U. STANCAR, Zabrze',
        'P.P.H.U. "ARKA" Sp. z o.o., Przysucha',
        'P.P.H.U. "GLOBAL" Andrzej Kuśmierski, Konopiska',
        'P.U.H. AUTO-MOT Artur Bartkowiak, Duszniki',
        'P.U.H. TAD-ROM, MIKOŁÓW',
        'P.W. Lech Nowicki, Witnica',
        'P&C Ciborski Auto Serwis, Jeziorany',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'PHU MASTER MECHANIKA POJAZDOWA, Konopiska',
        'PHU MKA Andrzej Antkiewicz, Dąbie',
        'PHU Moto-Ital, Wałbrzych',
        'PHU SUSKI, Trzydnik duży',
        'PHU Wolnik Mirosława Wolnik, Rębiechowo',
        'Pilichowski Marcin, Pniewo',
        'Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski ',
        'POLCAR Mechanika Pojazdowa Damian Polut, Potęgowo',
        'PPH BETROM ROMAN BETLEJEWSKI, OSTRÓW MAZOWIECKA',
        'PPHU Auto-Robex, Piaseczno ',
        'Pro-Cars, RZESZÓW',
        'Proikom s.c. SklepMotoryzacyjny, Warszawa',
        'Promar, Krynica Zdrój',
        'Przemysław Pietrzak, Biała',
        'PS AUTO PAWEŁ SŁANINA, BYTOM',
        'PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski, Bydgoszcz',
        'PUH POLMOZBYT SO, BOLESŁAWIEC',
        'PUH Wychodzki Maciej, Przeworno',
        'PUMIH PIOTR SZYMAŃSKI, GRODZISK MAZOWIECKI',
        'Punkt naprawy samochodów Mariusz Górny, Łobez',
        'Rafał Piskorz Mechanika pojazdowa, Kraków',
        'Rob-Car, Łochów',
        'RS SERWIS RAFAŁ SKOWRONEK, WŁODAWA',
        'Serwis Samochodów Ciężarowych Teresa Grygo, Suwałki',
        'SERWIS SAMOCHODOWY, KRAKÓW',
        'SIM SERWIS S.C., Złotoryja',
        'Sklep Motoryzacyjny "MEGA-MOTOR" Miłosz Barański, Bukowsko',
        'Speed Trans Kamil Kowalski, Wielun',
        'Stacja obsługi samochodów Włodzimierz Lesisz, Dęblin',
        'STARTER Dorota Kurczyńska, Kosakowo',
        'TAKS-CAR, WARSZAWA',
        'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE',
        'TIRPARTNER, ŁUKÓW',
        'TM AUTO Tomasz Marciniuk, Międzyrzec Podlaski',
        'Tom-Car, Biała Podlaska',
        'TOM-CARS, Kielce',
        'TOM-VIK TOMASZ TOPOLEWSKI, BYDGOSZCZ',
        'TOMIF CAR KATARZYNA WILIŃSKA FILIPCZAK, ŁÓDŹ',
        'Usługi Motoryzacyjne Marcin Bołoz, Piwniczna-Zdrój',
        'Usługi Transportowe Jan Ciak, Mrągowo',
        'Volwicar, łódź',
        'WROC CARS INŻYNIERIA POJAZDOWA DAWID NIEWIŃSKI, Wrocław',
        'XEGEN Mariusz Pąk, Uchanie',
        'Zakład Mechaniki Pojazdowej Andrzej Maciejewski, Bełzyce',
        'Zakład Mechaniki Pojazdowej Antoni Szubzda, Wasilków',
        'ZAKŁAD MECHANIKI POJAZDOWEJ TOMALA AUTO SERVICE TOMASZ BANASZEWSKI, SŁUPSK',
        'Zakład Usługowo-Handlowy Henryk Grala, WSCHOWA',
        'Zakład Usługowo-Handlowy Jadwiga Skwierawska, SŁAWNO',
        'ZUH MOTO-BAZAR, RADOM',
        'ZYGI-AUTO, Stary Węgliniec',
      ]
    },
    {
      dataTab: '04.10-10.10',
      winnersList: [
        '"BASTO" MECHANIAKA POJAZDOWA SEBASTIAN GOMOLA, USTROŃ',
        '"LIZ" AUTOSERWIS S.C LESZEK KOWALEWSKI, ZBIGNIEW ZBOINA, PIASTÓW',
        '"SEBO", PIEŃSK',
        'A.S.C. USŁUGI MOTORYZACYJNE ARTUR MALON, OSTROŁEKA',
        'A.T. SERVICE KRZYSZTOF NOWICKI, BIELSKO-BIALA',
        'ABC AUTO S.C., ZIELONA GÓRA',
        'ACTIVE 2000 JACEK GROTKOWSKI, KONSTANCIN-JEZIORNA',
        'AD SERWIS AGROBUD, MYŚLENICE',
        'ADAM KOWALSKI, GDAŃSK',
        'ADRIAN KOT, LUBLIN',
        'ALL CARS, MAJDAN GÓRNY',
        'ANMAR MAREK PAŹDZIOR, SIEMIATYCZE',
        'ARCAR MECHANIKA SAMOCHODOWA, MAŁDYTY',
        'ARIS-AUTO, STARGARD SZCZECIŃSKI',
        'AUGUSTYNIAK SERWIS, PRUSZKÓW',
        'AUTO - CZĘŚCI KURCEK - KORAL S.C., MEDYKA',
        'AUTO A.B ANDRZEJ BOGUSZ, TRĄBKI',
        'AUTO CZĘŚCI OLSZA MARTA MENDEL, KRAKÓW',
        'AUTO GUCIO PAWEŁ GĄSIOREK, ŁĘGOWO',
        'AUTO KLINIKA GRZEGORZ LUBOCKI, LĘBORK',
        'AUTO KLINIKA NOSARZEWSKI, STARA IWICZNA',
        'AUTO KOSIK, GDAŃSK',
        'AUTO MECHANIK SERWIS TOMASZ DUDEK, ROGÓW',
        'AUTO MECHANIKA GIERA PIOTR, RYMANÓW',
        'AUTO MECHANIKA MARCIN WAWOK, RYBARZOWICE',
        'AUTO MIŚ, SZCZECIN',
        'AUTO NAPRAWA, BOLKÓW',
        'AUTO NAPRAWA "TACAR", ZAMBRÓW',
        'AUTO NAPRAWA ŁUKASZ SZMIGIEL, MRĄGOWO',
        'AUTO PARTNER, BIAŁA PODLASKA',
        'AUTO SERVICE PLUS OBACZ PIOTR, BIELAWA',
        'AUTO SERWIS, GDYNIA',
        'AUTO SERWIS ANDRZEJ ZBĄDZKI, OSIEK',
        'AUTO SERWIS BARTOSZ OGONOWSKI, RADZYŃ PODLASKI',
        'AUTO SERWIS BOLANOWSKI, RYMANÓW ',
        'AUTO SERWIS JAROSŁAW SYREK, MODLNICA',
        'AUTO SERWIS MARTA WOJTERA, WARSZAWA',
        'AUTO SERWIS MEGUIN, RYKI',
        'AUTO SERWIS NOWAK, SULECHÓW',
        'AUTO SERWIS PAWEŁ POCHANKE, WRONIAWY',
        'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
        'AUTO SERWIS RAFAŁ KOCHANEK, FOLWARK ',
        'AUTO SERWIS RAFAŁ RAK, BYTOM',
        'AUTO SERWIS SIADUL MATEUSZ, DOBRZYCA',
        'AUTO SERWIS STRZYKUŁY, STRZYKUŁY',
        'AUTO SERWIS ZIELIŃSKI, MALBORK',
        'AUTO SZYBY-CZĘŚCI MARIUSZ RECLAF, KOŚCIERZYNA',
        'AUTO TRONIC JUSTYNA PISKOZUB, POZNAŃ',
        'AUTO-ALFA 1 GRZEGORZ HODUREK, SUŁKOWICE',
        'AUTO-BAZAR S.C., KALISZ',
        'AUTO-COMP CZĘŚCI DP DANIEL PATERAK, POLANICA ZDRÓJ',
        'AUTO-EURO-CZĘŚCI, BEŁCHATÓW',
        'AUTOEXPERT, ZIELONA GÓRA',
        'AUTO-EXPRES S.C. L.KITOWSKI S.WÓJCIK, GDAŃSK',
        'AUTOGREX, CHROMÓWKA',
        'AUTO-JAR JAROSŁAW JAWORSKI, RADOM',
        'AUTO-KLIMAT, KONOPISKA',
        'AUTOMAR  MECHANIKA POJAZDOWA  JAROSŁAW MARCINKOWSKI, POŁCZYN-ZDRÓJ',
        'AUTOMAX JACEK BAGIŃSKI, KOLOBRZEG',
        'AUTOMECHANIKA, GOŁDAP',
        'AUTO-MECHANIKA, ZAMOŚĆ',
        'AUTO-MOTOR, OSTROWIEC ŚWIĘTOKRZYSKI',
        'AUTONAPRAWA, GÓRA',
        'AUTO-NAPRAWA RADOSŁAW NASIEROWSKI, CIECHANÓW',
        'AUTO-SERWIS PIOTR HAJDUK, TARNOWSKIE GÓRY ',
        'AUTOSET, DĄBROWA',
        'AUTOSFERA , DYLEWO',
        'AUTO-SKLEP-SERWIS, LEWIN BRZESKI',
        'AUTO-SPEC, BOGUMIŁOWICE',
        'AUTOSYSTEM BLACHARSTWO LAKIERNICTWO MECHANIKA POJAZDOWA JARO, ZABŁUDÓW',
        'AUTO-TECH RZEPKA WALDEMAR, RYCHWAŁ',
        'AUTOTIME NAPRAWA POJAZDÓW, SZCZECIN',
        'BG TURBO BARTOSZ GULINA, NOWE MIASTO NAD PILICĄ',
        'BIG JOHNY GARAGE, SKOWARCZ',
        'BLACHARSTWO I LAKIERNICTWO MECHANIKA POJAZDOWA BRUNON WEISNER , RYJEWO',
        'BORUTA TEAM, SZCZECIN',
        'CAROCK, DOBROMIERZ',
        'CAR-SERVICE, CZESTOCHOWA',
        'CAR-SERWIS, ŁAŃCUT',
        'CENTRUM OBSŁUGI POJAZDÓW S.C. SŁAWOMIRA KUBACKA,ARKADIUSZ KUBACKI, LUBAWKA',
        'CENTRUM SAMOCHODOWE TOMASZ MROCZKOWSKI , GORZÓW WIELKOPOLSKI ',
        'CITYCAR MACIEJ GĄSIOREK, NOWE SKALMIERZYCE',
        'COLDMARK, ŁOSICE',
        'COMPLETE REPAIR SERVICE, MIELEC',
        'DACAR, STARGARD',
        'DAWID SZYMAŃSKI SYNEKTUNE, ANTONINÓW',
        'DIAX MOTORSPORT, STRZENIÓWKA',
        'DKMD-SERVICE, ŁÓDŹ',
        'ELEKTROMECHANIKA, OSTRÓW WLKP',
        'ELEKTROMECHANIKA KAMIL GRINHOLC, KĘBŁOWO',
        'ELEKTROMECHANIKA POJAZDOWA, WARSZAWA',
        'ELMEC, KOSCIELISKO',
        'EURO-MIX, GDYNIA',
        'EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ',
        'EWA SZWEDA, RUDA ŚLĄSKA',
        'EXTRUCKT SP. Z O.O, WARSZAWA',
        'F U H EURO-MOTO, CZECHOWICE-DZIEDZICE',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, ĆWIKÓW',
        'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
        'F.H.U DIESEL NAPRAWA TOMASZ HAJDA, ZAWOJA',
        'F.H.U. "MECHANIC" KIELAN ŁUKASZ, RAWA MAZOWIECKA',
        'F.H.U. DUDEK AGNIESZKA ZIÓŁKOWSKA, BRZESKO',
        'F.H.U.P. BARBARA WAWRZEŃCZYK, MORAWICA',
        'F.H.U.P. OLA, SĘDZISZÓW',
        'FAST-SERWS DAMIAN PIEKARZ MECHANIKA POJAZDOWA, TARNOBRZEG',
        'FHU BEMAR, SZADEK',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
        'FHU GABRIEL KOSTKA, DŁUGOMIŁOWICE',
        'FIRMA HANDLOWO - USŁUGOWA ŁUKASZ BUCIAK, BIELANY WROCŁAWSKIE',
        'FIRMA USŁUGOWA AUTO-NOWAK ANDRZEJ NOWAK, MOGILNO',
        'FIRMA WIELOBRANŻOWA DARIUSZ SIKORA, GLIWICE',
        'FORDCAR S.C. D. KŁAWSIUĆ R.MAKAŚ Z.JEWIARZ M.SZYMAŃSKI, SZCZECIN',
        'GEARBOX , NOWOGARD',
        'GEMINI AUTO MAX SERWIS ŁUKASZ OSAŁKOWSKI, BYDGOSZCZ',
        'GINTER AUTO MARIUSZ WALCZAK, ŁOSICE',
        'HARRYS CARS SŁAWOMIR ZLOT, LUBLIN',
        'HOSA, OŚWIĘCIM',
        'IMPO-MOTO JAN CHRABĄSZCZ, ZAMOŚĆ',
        'INTERTRANSPORT SP. Z O.O., RADZYŃ PODLASKI',
        'JAN WOŹNIAK, JANOWIEC WIELKOPOLSKI',
        'JARCAR1, SĘDZISZÓW MAŁOPOLSKI',
        'JARWIT JAROSŁAW SŁOWIŃSKI, DĘBNO',
        'K&R AUTO SERVIS - ZAKŁAD MECHANIKI POJAZDOWEJ STAROŚCIAK KAMIL, OPOLE',
        'KAMPOL1 RAFAŁ LOFEK, BYDGOSZCZ',
        'KAROL CZARNECKI, RADOM',
        'KOMIS SAMOCHODOWY GOLDCAR MARZANNA MORITZ  , ŁUKÓW',
        'KOPEX, KIELCE',
        'KRAKPOL, MILEJÓW',
        'KROMAC SERWIS, SMARDZEW',
        'KT SERWIS KONRAD TUSIEWICZ, MARKI',
        'LM2, PRUCHNIK',
        'M&M AUTO SERWIS, ZGIERZ',
        'M&P IMPORT-EKSPORT ANDRUSIUK PIOTR, LEŚNA PODLASKA',
        'M.H.SERWIS, LUBLIN',
        'MAGIC FAST LISIK MARIUSZ, OLEŚNICA',
        'MARCAR, KOŚCIELISKO',
        'MARCIN ADAMCZYK, BRZESKO',
        'MARK TECH CAR, BRANIEWO',
        'MARK-MOT, CHEŁM',
        'MARMUR, KOŁBIEL',
        'MAT SERWIS , PRZEGINIA',
        'MAT-AUTO TOMASZ ŚWIDERSKI, TYCHNOWY',
        'M-CAR TECHNOLOGY, CIESZYN',
        'MECHANIA POJAZDOWA, MIRZEC',
        'MECHANIKA POJAZDOWA DARIUSZ PIESIK, GDYNIA',
        'MECHANIKA POJAZDOWA EM-CAR, ZAGNAŃSK',
        'MECHANIKA POJAZDOWA GRZEGORZ DEBICKI , PASZOWICE',
        'MECHANIKA POJAZDOWA HENRYK SOPATA , ŁĄCKO',
        'MECHANIKA POJAZDOWA HUDECKI, ANDRYCHÓW',
        'MECHANIKA POJAZDOWA INŻ. JAROSŁAW NOWACZYK, WĄSOSZ',
        'MECHANIKA POJAZDOWA JAROSŁAW CIARKOWSKI, NOWY DWÓR GDAŃSKI',
        'MECHANIKA POJAZDOWA KONRAD GOŁĘBIEWSKI, BOROWA GÓRA',
        'MECHANIKA POJAZDOWA KRZYSZTOF KOWALEWSKI, BIAŁYSTOK',
        'MECHANIKA POJAZDOWA KRZYSZTOF MACKOWSKI, LESZNO',
        'MECHANIKA POJAZDOWA ROBERT GRZESIAK, LUTUTÓW',
        'MECHANIKA POJAZDOWA ROMAN HERMAN, CZERWONAK',
        'MECHANIKA POJAZDOWA RYSZARD ŁUCZAK, KOŚCIAN',
        'MECHANIKA POJAZDOWA T. I D. ŻARNA S.C., TUREK',
        'MECHANIKA POJAZDOWA TOMASZ NAMYSŁO, ŻYWOCICE',
        'MECHANIKA POJAZDOWA ZATOR EDWARD, ŻAGAŃ',
        'MECHANIKA POJAZDOWA ZENON GADZIŃSKI, MIELŻYN',
        'MECHANIKA POJAZDOWA, WULKANIZACJA SOŁTYSIAK BOGUSŁAW, OSTRÓW WIELKOPOLSKI',
        'MECHANIKA POJAZDOWABLACHARSTWOLAKIERNICTWO ANDRZEJ KOWALCZYK, ZIELONA GÓRA',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'MECHANIKA SAMOCHODOWA, PRUDNIK',
        'MECHANIKA SAMOCHODOWA, CHARSZNICA',
        'MECHANIKA SAMOCHODOWA MARCIN JANICKI, DĘBICA',
        'MEGA-KORBUS KORBUS ALICJA MAŁGORZATA SKLEP CZĘŚCI SAMOCHODOW, OŚNO LUBUSKIE',
        'MIREX - SZANDECKI I MARCZUK SP.J., BIAŁA PODLASKA',
        'MMK-MOTO, MYSLENICE',
        'MOBILEK CZĘŚCI SAMOCHODOWE BARTOSZ KUBACH, SUSZ',
        'MOBIL-TOM TOMASZ JEZNACH, STRASZYN',
        'MONSTER GARAŻ MARIUSZ ŻABKA, SZCZAWIN KOŚCIELNY',
        'MONZA-OIL MARLENA WŁODARCZYK, ŚWIDNIK',
        'MOTO BUDREX, INOWROCŁAW',
        'MOTOBAJ TOMASZ ŻYNIS, MRAGOWO',
        'MOTO-FAN S.C., PRZEMYŚL',
        'MOTOMARK WOJCIECH SZUMICKI, SIERADZ',
        'MOTO-SKLEP GOWOREK I SPÓŁKA SP.J., TOMASZÓW MAZOWIECKI',
        'MOTOSTARTER, WROCŁAW',
        'MT GUM, NISKO',
        'MTD CAR, RADZYMIN',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'NOWAKSERVIS, TWARDAWA',
        'OLICAR 2 MONIKA MISZTAL, BEDZIN',
        'ORANGE-MOTO JAKUB ŁUKASZEWSKI , BIELSKO-BIAŁA',
        'P H U DARKO DARIUSZ OSADNIK, SKOMLIN',
        'P. H. U. MAGDALENA URBAN , CHODEL',
        'P.H.U. WIELICZKO, SYCÓW',
        'P.J.MAX PIOTR JANOWSKI, ZIELONA GÓRA',
        'P.P.H.U.FRODO WIESŁAW POLNICKI, PIEŃSK',
        'P.U.H. STARTER AUTO- ELEKTRYK ROMAN BARSKI, TCZEW',
        'P.U.H. TAD-ROM, MIKOŁÓW',
        'P.W AUTO-COMPLEX, GNIEWKOWO',
        'PERFECTCAR, LUBLIN',
        'PHU ;ALTROZ; TOMASZ ZAŁUCKI, PNIEWY',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'PHU BŁASZCZYK TOMASZ BŁASZCZYK, CIECIERZYN',
        'PHU MKA ANDRZEJ ANTKIEWICZ, DĄBIE',
        'PHU WOLNIK MIROSŁAWA WOLNIK, RĘBIECHOWO',
        'PHU"KM"KAZIMIERZ BREGUŁA, BORUSZOWICE',
        'PIM AUTO SERWIS S.C., KRAKÓW',
        'PIT STOP AUTO SERWIS, LWÓWEK ŚLĄSKI',
        'PLUSOIL, PUŁAWY',
        'PPH BETROM ROMAN BETLEJEWSKI, OSTRÓW MAZOWIECKA',
        'PPHU ANET ANETTA RAŹNIAK-ROBAK, NIECHCICE',
        'PPHU BART-POL, PAKOSŁAW',
        'PPHU WILUX, HAJNÓWKA',
        'PREMIUM CAR SERVICE PIT STOP, DZIAŁDOWO',
        'PRODEX JOANNA WILK, WARSZAWA',
        'PROSIT CENTER SP.Z.O.O, WARSZAWA',
        'PRUSINOWSKI CAR SERVICE, GODZIESZÓW',
        'PRZEDSIEMBIORSTWO HANDLOWO USLUGOWE, WOJNICZ',
        'PUH AUTO-NAPRAWA-OSTROWSKI MARIUSZ OSTROWSKI, BYDGOSZCZ',
        'PUH WYCHODZKI MACIEJ, PRZEWORNO',
        'PUNKT NAPRAWY SAMOCHODÓW MARIUSZ GÓRNY, ŁOBEZ',
        'R2 AUTO SERWIS ARTUR KUREK, OPOLE',
        'RYSTAL, PLESZEW',
        'SERVICE PLAN MARCIN ZENOWICZ, BĘDZIN',
        'SKLEP MOTORYZACYJNY - PAWEŁ NOWAK, ŻARY',
        'SKS MOTO DARIUSZ SZYMAŃSKI, WIELICZKA',
        'SLEEVE&PACK KRZYSZTOF CZARNOWSKI, WARSZAWA',
        'SPEED CAR, KĘDZIERZYN-KOŹLE',
        'SPEED SERVICE , ŁOWICZ',
        'SPRZEDAŻ I SERWIS PODZESPOŁÓW SAMOCHODOWYCH M.GOSK, ZAMBRÓW',
        'STEBLEJ CAR SERVICE, STRONIE ŚLĄSKIE',
        'STP-SERWIS PRECH SŁAWOMIR, TRZEMESZNO',
        'SZABELAK MOTORS, KOŃSKIE',
        'TC SERWIS, MIRKÓW',
        'TECHTOM, TRZEBOŚ',
        'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE',
        'TL-MECHANIKA SAMOCHDOWA, WEGRZCE WIELKIE',
        'TOM-CARS, KIELCE',
        'TOP SP. Z O.O., KRAKÓW',
        'TOTAL-TRUCK-TIR-SERWIS S.C. RADOSŁAW KMIN TOMASZ STĘPIEŃ, PABIANICE',
        'TRANSPORT PAWEŁ WNUKOWSKI  (TŁUMIKI), WARSZAWA',
        'USŁUGI TRANSPORTOWE JAN CIAK, MRĄGOWO',
        'UWEX, PASZYN',
        'V&V AUTO, KOCZARGI NOWE',
        'VOLKSKLINIKA, POZNAŃ',
        'WARSZTAT SAMOCHODOWY, STRZEBIELINO',
        'WARSZTAT SAMOCHODOWY ARKADIUSZ FENDOR, ZAPOLICE',
        'WARSZTAT SAMOCHODOWY MAREK MOSKAL, STRÓŻA',
        'WĘGRZYN & WĘGRZYN - AUTO SERWIS WĘGRZYN S.J., KRAKÓW',
        'WITOLD RYMON-LIPIŃSKI AUTONAPRAWA WITEK, CHOJNICE',
        'WOJCIECH BORKOWSKI, WARSZAWA',
        'WOJCIECH KOWAL AUTOSERWIS, KAŃCZUGA',
        'WPUH PROGRES SPÓŁKA Z O.O., CHOJNA',
        'Z.U. SOBECKI DANIEL SOBECKI, PRZECISZÓW',
        'Z.U.H MOTO-GOSZ KRYSTIAN MIEDZIŃSKI, LĘBORK',
        'ZAKŁAD MECHANIKI POJAZDOWEJ DAMIAN FELKA, BRODNICA',
        'ZAKŁAD MECHANIKI POJAZDOWEJ GRZEGORZ BYCZEK , OSIĘCINY',
        'ZAKŁAD MECHANIKI POJAZDOWEJ ŁUKASZ BREJNAKOWSKI, ŁOBEZ',
        'ZAKŁAD MECHANIKI POJAZDOWEJ TOMALA AUTO SERVICE TOMASZ BANASZEWSKI, SŁUPSK',
        'ZAKŁAD MECHANIKI POJAZDOWEJ WICAR, NOWA SŁUPIA',
        'ZAKŁAD MECHANIKI POJAZDOWEJ WOJCIECH PIEKARSKI, KALISZ',
        'ZAKŁAD MECHANIKI POJAZDOWEJ ZBIGNIEW MISIAK, ŁUKÓW',
        'ZAKŁAD WULKANIZACJI LECH FRANKOWSKI I SYN, POLICE',
        'ZDZISŁAW JAN PLEŃKOWSKI ZAKŁAD MECHANIKI POJAZDOWEJ, DZIAŁDOWO',
        'ZYGI-AUTO, STARY WĘGLINIEC',
      ]
    },
    {
      dataTab: '11.10-17.10',
      winnersList: [
        '"AUTO ROGER" - DANIEL RYZNER -ZĄBKOWICE ŚLĄSKIE',
        'AD SERWIS AGROBUD -MYŚLENICE',
        'Adis Jaroslaw Ściesiński -Dobrcz',
        'AMS MOTO SERWIS -Nowy Sącz',
        'Auto - serwis Robert Chorążyk -częstochowa',
        'Auto Akces Shop Pacholska Ewelina -Inowrocław',
        'AUTO CENTRUM CZESŁAW CHYLAK -KĘTRZYN',
        'AUTO CENTRUM -RADZIONKÓW',
        'Auto Craft Helena i Zbigniew Wowk Sp.J. -Stalowa Wola',
        'Auto Części Mariusz Dybalski -Żarnów',
        'Auto Detailing Na 6 -Krosno ',
        'Auto Fan Tomasz Kargiel. -Słupia',
        'AUTO FULL SERWIS -Siedliska',
        'AUTO GARAGE-TECŁAW -Kutno',
        'AUTO GUCIO Paweł Gąsiorek -ŁĘGOWO',
        'AUTO KOSIK -GDAŃSK',
        'AUTO MAJCHRZAK CEZARY MAJCHRZAK -DOLICE',
        'Auto Marlon Serwis R.Balwierz, W.Dobosz S.C. -Jasło',
        'Auto Mechanika -Frampol',
        'Auto Miś -Szczecin',
        'AUTO MOTO CENTRUM Jan Haraburda -Augustów',
        'Auto Moto Luiza Jaworska -Kutno',
        'AUTO NAPRAWA EDWARD SITEK -HAżLACH',
        'Auto Naprawa Erni -Kędzierzyn Koźle',
        'Auto Naprawa Handel Paweł Góral -Gniezno',
        'Auto Naprawa Łukasz Szmigiel -Mrągowo',
        'AUTO NAPRAWA -Zalesie',
        'AUTO PLUS STACJA KONTROLI POJAZDÓW  -Jasło',
        'Auto Serwis Adam Tarka -Grajewo',
        'AUTO SERWIS Damian Piekarczyk -Wisła Wielka',
        'Auto Serwis Damian Wisniewski  -Kurzetnik',
        'AUTO SERWIS G-7 -ZABRZE',
        'Auto Serwis Jarosław Syrek -Modlnica',
        'Auto Serwis Mechanika-Wulkanizacja Mariusz Ołdak -Warszawa',
        'Auto Serwis Strzykuły -Strzykuły',
        'Auto serwis -UDANIN',
        'AUTO TECHNIK -Szczecin',
        'AUTO-ALFA 1 GRZEGORZ HODUREK -SUŁKOWICE',
        'AutoCentrum "Oskar" -Kolbuszowa',
        'Auto-Części Jacek Tworek -Nowogard',
        'AUTO-CZĘŚCI TOMASZ SUDER -WIELICZKA',
        'AUTODAK S. C. -Mysłowice',
        'Auto-Ekspert Robert Brzozowski -Suwałki',
        'Autoexpert -Koszalin',
        'AUTOEXPRES -WĘGIERSKA GÓRKA',
        'Autoklima serwis klimatyzacji samochodowej Grzegorz Różański -Iwierzyce ',
        'AUTO-KLINIKA -Krosno',
        'AUTO-LAK -ALWERNIA',
        'AUTOMAR -Radomyśl Wielki',
        'AUTO-MARK P.P.H.U. KONRAD MŁUDZIK -ŁÓDŹ',
        'AUTOMARKOS Usługi samochodowe Marek kosecki -Konarzyny',
        'AUTOMAX G.GAJEWSKI -OSTRÓW MAZOWIECKA',
        'AUTOMAX Jacek Bagiński -Kołobrzeg',
        'AUTO-MAX -Wąglikowice',
        'Auto-Naprawa  -Pilzno',
        'AutoNaprawa -Góra',
        'AUTO-NAPRAWA Molendowski Wiesław SERVICE SAMOCHODOWY BOSCH -RADOM',
        'AUTO-PORĘBA -KATOWICE',
        'Auto-Service & FIACIK -Zabrze',
        'AUTO-SERWIS KATARZYNA SZAJNOWSKA -ZAŁOM',
        'AUTO-SERWIS ROMUALD SZWOCH -Tuchola',
        'Autoserwis -Sieradz',
        'Auto-sklep-serwis -Lewin Brzeski',
        'AUTOTECHNIK Łukasz Rodzeń -Gorzów Wielkopolski',
        'AUTO-TOP S.C. -KROSNO',
        'AVIZ-EUROPA ANDRZEJ GRULKOWSKI -GDAŃSK',
        'Big Johny garage -Skowarcz',
        'Bosch Car Service KUCHARCZYK & WITUCK -Zielona Góra',
        'Bulek Marcin Cybulski -Toruń',
        'CaRock -Dobromierz',
        'CAROS-SERVICE Wojciech Głowaty -Wrocław',
        'Centrum Samochodowe Tomasz Mroczkowski  -Gorzów Wielkopolski ',
        'CUM SKORPION -Krosno',
        'DaCar -Stargard',
        'Dagpon -Witkowo',
        'Diagnostyka Badania Techniczne Pojazdów -Brzeziny',
        'Drewnikowski sp. z o.o. -Szczecin',
        'Eldorado Krzysztof Piergies -Nowogrodziec',
        'Elektomechanika Dworczak Mariusz -Lipno',
        'Elektoromechanika Zbigniew Wołowski -Łódź',
        'Elektromechanika i mechanika pojazdowa auto hobby -Brzeźce',
        'Elektromechanika Kamil Grinholc -Kębłowo',
        'Elektromechanika Pojazdowa Artur Krzywański -CZęstochowa',
        'Elektromechanika Zbigniew Augustyn -Ostróda',
        'Expert s.c. -Biskupiec',
        'Extruckt sp. z o.o -warszawa',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA -Ćwików',
        'F.H.U. ADAR Adam Pach -Ruda Śląska',
        'FH J-P Joanna Pasternak -Dąbrowa Tarnowska',
        'Fhu "Tomasiewicz" s.c. -Pisz',
        'FHU CARSERVICE PIOTR RATAJCZYK -KAMIENNA GÓRA',
        'Fhu Damian Kalisiak -Wolin',
        'FHU DARIUSZ KORZEŃ -ROPA',
        'FHU Gekon Błażej Skubisz -Humniska',
        'FHU Ratajczyk -Kalisz',
        'FHU SZIK  -Rzeszów',
        'Firma Handlowo - Usługowa Łukasz Buciak -Bielany Wrocławskie',
        'FIRMA HANDLOWO-USŁUGOWA PRODUKCYJNA  DB-AUTO DOMINIK BUŁAŚ -krasne',
        'Firma usługowa Arkadiusz Ciskowski -Mrągowo',
        'Firma Usługowa Auto Naprawa -Grabica',
        'Firma Usługowa Auto-Nowak Andrzej Nowak -Mogilno',
        'Firma zielpol Jerzy Zielonka -Piotrków Trybunalski',
        'GMT CARS SERVICE -Słupsk',
        'GP Auto Garage Przemysław Gąciarz -Gryfino',
        'Intertransport Sp. z o.o. -Radzyń Podlaski',
        'Japan Service -warszawa',
        'Jaro-Filtr -Radzymin',
        'JP Serwis -Paździorno',
        'Julcar FHU S.C. Podolak Marcin-Podolak Paulina -STRZYŻÓW',
        'Jul-car -Włocławek ',
        'Karol Czarnecki -Radom',
        'KOŁODYŃSKI GARAŻ -STRZEGOM',
        'KT Serwis Konrad Tusiewicz -Marki',
        'Łopion & Łopion -Namysłów',
        'M&M Auto Serwis -Zgierz',
        'M&P IMPORT-EKSPORT ANDRUSIUK PIOTR -Leśna Podlaska',
        'M.S. SERVICE -Kraków',
        'Maksman Garage -Stare Czarnowo ',
        'Marcin Jabłoński Auto Serwis Fordzik -Piła',
        'Mark-Mot -Chełm',
        'Marmot Marek Smolarczyk -Kłomnice',
        'Mechanika Pojazdowa "Auto Jack" Jacek Jakubiec -Łodygowice',
        'Mechanika Pojazdowa Arkadiusz Ginter -Osieczna',
        'Mechanika Pojazdowa EM-CAR -Zagnańsk',
        'Mechanika Pojazdowa Handel Częściami i Transport W Szymański -Stawiszyn',
        'MECHANIKA POJAZDOWA HUDECKI -ANDRYCHÓW',
        'MECHANIKA POJAZDOWA Krzysztof Hutek -Limanowa',
        'MECHANIKA POJAZDOWA MARIUSZ HUBCZUK -Braniewo',
        'Mechanika Pojazdowa Mariusz Kędziora -Głowno',
        'Mechanika Pojazdowa -Piotrków Tryb',
        'Mechanika Pojazdowa Pomoc Drogowa Jarosław Przybysz -Garwolin',
        'MECHANIKA POJAZDOWA -PRZEWORSK',
        'MECHANIKA POJAZDOWA RAFAŁ HERBIK -NOWE SKALMIERZYCE',
        'Mechanika Pojazdowa Tadeusz Chudon -Tczew',
        'Mechanika pojazdowa zbigniew szulc -Duszniki',
        'Mechanika samochodowa Jarosław Bejuk -Milicz',
        'Mechanika Samochodowa Marcin Janicki -Dębica',
        'Mechanika Samochodowa -Prudnik',
        'medard-auto@o2.pl -Parczew',
        'MIREX - Szandecki i Marczuk Sp.J. -Biała Podlaska',
        'Monfils -Opacz Kolonia',
        'Motomik -wrocław',
        'Moto-Partner -Szczecin',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH GRZEGORZ ANDRUSZKIEWICZ -PASŁĘK',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH -LUBLINIEC',
        'OBER -kłaj',
        'P.H.U Piotr Palkowski -Mława',
        'P.H.U.AUTO-CZĘŚCI DARIUSZ WOŹNICKI -IŁAWA',
        'P.U.H. TAD-ROM -MIKOŁÓW',
        'P.U.H."KOLON " TOMASZ KOLON -CZARKÓW',
        'PAMAR s.c. -Gdynia',
        'Paprocki Auto Serwis -Barczewo',
        'PHU "GOL-MOT" Krzysztof Gołębiecki -Brańsk',
        'Phu Amakares Arkadiusz Marczuk -Szczecin',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI -BRZEZINY',
        'PHU MAR CAR Mariusz Gorzoń -Dobryszyce',
        'PHU MARGO  -Łomża',
        'PHU MKA Andrzej Antkiewicz -Dąbie',
        'PHU MOTOGAMA JERZY CZYŻEWSKI -Grabówka',
        'phu spychalski wojciech -drezdenko',
        'Picar s.c Barbara Pisarczyk Łukasz Pisarczyk -Stanisławów',
        'PILZAK AUTO Damian Pilzak -Nowodwór',
        'Piotr Motors Zakład Mechaniki Pojazdowej Piotr Michalik -łabiszyn',
        'Piotrowska Elżbieta  -CHOJNA',
        'PM PETROLEN PRZEMYSŁAW ŚLĘZAKIEWICZ -RADOMSKO',
        'Podlaski Zakład Motoryzacyjny Sp. z o.o. -Biała Podlaska',
        'PPHU ARIS -ZGIERZ',
        'Przemysław Pietrzak -Biała',
        'PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski -Bydgoszcz',
        'PUH POLMOZBYT SO -BOLESŁAWIEC',
        'Punkt naprawy samochodów Mariusz Górny -Łobez',
        'Q Service HŁADKA -Gorzow Wlkp',
        'QS Service -Ostrołęka',
        'Rafał Piskorz Mechanika pojazdowa -Kraków',
        'Raf-car -Lipno',
        'RAFIX SERWIS RAFAŁ MŁYNARSKI -Lublin',
        'RemiCar Remigiusz Świtalski -Wrocław',
        'REWEX -Legnica ',
        'ROM-CAR -Myszków',
        'Sab-service Marcin Sabała -Kozłowo',
        'Serwis mobilny leg kar pawel karbowiak -Legnica',
        'SERWIS SAMOCHODOWY PAPCAR Sp.z o.o. -Bogoria',
        'Somiko usługi motoryzacyjne Edyta Sochacka -Radom',
        'Stacja kontroli pojazdó Wojciech Włodarczyk -Wieluń',
        'STALKO -Zielonka',
        'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY -ZALESIE',
        'TIRPARTNER -ŁUKÓW',
        'TOM-CARS -Kielce',
        'TOMEX AUTOSERWIS TOMASZ KONATOWICZ -RADOM',
        'Usługi Motoryzacyjne AUTOMOBILE Piotr Kupka -Bełchatów',
        'Usługi Motoryzacyjne Marcin Bołoz -Piwniczna-Zdrój',
        'V. 8. Aleksander Maliszewski -Głogów',
        'VAG Performance Kozłowski Paweł -Krasnosielc',
        'van serwis KASPRZAK SZYMON -ZAMOŚĆ',
        'Warsztat Mechaniki Pojazdowej Łukasz Szymkowiak -Środa wlkp',
        'Zaj-Mech Serwis Łukasz Zając -Białystok',
        'ZAKLAD MECHANIKI POJAZDOWEJ P.JAWORSKI -LUBOSZYCE',
        'ZAKŁAD MECHANIKI I ELEKTR.SAM. -OLSZTYN',
        'Zuh Autos baj spółka jawna -DREZDENKO',
        'ZUH MOTO-BAZAR -RADOM',
      ]
    },
    {
      dataTab: '18.10-24.10',
      winnersList: [
        '"ARKO" ARKADIUSZ I TOMASZ MROZIŃSCY SP. JAWNA, CZĘSTOCHOWA',
        'AC-MOTO Adam Cencora, Kraczkowa',
        'AEC  INVEST ANNA  ĆWISZEWSKA, JELENIA  GÓRA',
        'AGRO-MOTO majster Daniel Cichecki, Szczekociny',
        'AGROSS S.C., KOZIEGŁOWY',
        'ANTER S.C., PANIÓWKI',
        'Art-Bil Damian Bilski, Radom',
        'AUGUSTYNIAK SERWIS, Pruszków',
        'Auto Borys, Siedlisko',
        'AUTO CENTRUM, RADZIONKÓW',
        'AUTO EXPERT KAROLINA ADEREK, RADOM',
        'AUTO GUCIO Paweł Gąsiorek, ŁĘGOWO',
        'Auto Handel usługi Krzyszczyk Joachim, Wieszowa',
        'AUTO JANOCHA PIOTR JANOCHA, Jeszkowice',
        'AUTO KLINIKA  TOMASZ KULIK, DĘBLIN',
        'AUTO KLINIKA NOSARZEWSKI, sTARA IWICZNA',
        'AUTO KOSIK, GDAŃSK',
        'AUTO MECHANIK SERWIS Tomasz Dudek, Rogów',
        'auto Mechanika, Frampol',
        'Auto Miś, Szczecin',
        'AUTO MOTO KLINIKA KRZYSZTOF GALA, Jaworzno',
        'Auto Moto naprawa Andrzej Chmielewski , Swidwin',
        'Auto Naprawa Krzysztof Kroll, Ostrów Wielkopolski',
        'Auto Naprawa ROBI-CAR Robert Soczewka, Rudno',
        'AUTO NAPRAWA SZAŁAGAN PRZEMYSŁAW SZAŁAGAN, ŻARY',
        'Auto Naprawa U Andruli, Suwałki',
        'AUto partner s.c., Libiąż',
        'AUTO POMOC Paweł Kluziak, Gdańsk',
        'AUTO SERWIS GRZEGORZ CHUDZICKI, KŁAJ',
        'AUTO SERWIS ŁUKASZ NOWAK, KOCMYRZOW',
        'AUTO SERWIS MARCIN STRZELECKI, PRZEWORNO',
        'Auto Serwis Mariusz Łachut, Mielec',
        'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
        'Auto Serwis s.c. Małgorzata Tkaczyk Marek Tkaczyk, Suwałki',
        'Auto Serwis Strzykuły, Strzykuły',
        'auto serwis vip korycki marcin, radom',
        'AUTO SERWIS ZIELIŃSKI, Malbork',
        'Auto Strefa Serwis Samochodowy Tomasz Kafel, Krynica-zdrój',
        'Auto Zgrzyt Łukasz Ornat, Sulbiny',
        'AUTO-ALFA 1 GRZEGORZ HODUREK, SUŁKOWICE',
        'Auto-Części Jacek Tworek, Nowogard',
        'AUTO-CZĘŚCI TOMASZ SUDER, WIELICZKA',
        'AUTO-KLINIKA, Krosno',
        'Auto-Kros, Krosno',
        'AUTO-NAPRAWA Radosław Nasierowski, Ciechanów',
        'Auto-roki, Poniatowa',
        'auto-serwis adam sypniewski, bielawa',
        'AUTO-SERWIS ROMUALD SZWOCH, Tuchola',
        'AUTO-SERWIS SKODA SEAT VOLKSWAGEN AUDI, Wrocław',
        'Auto-Sklep Auto-Naprawa A.Rutkowski, Malbork',
        'AUTO-SZPAN, Łomnica',
        'AUTO-TOP S.C., KROSNO',
        'Auto-Wojak , Szczecin',
        'AUTODAK S. C., Mysłowice',
        'Automania, Drzycim',
        'AUTOMAX Jacek Bagiński, kolobrzeg',
        'Automechanika Krzysztof Pawlak, Zielona Góra',
        'AUTONAPRAWA, Zawoja',
        'AUTONAPRAWA, Zielona',
        'Big Johny garage, Skowarcz',
        'BLACHARSTWO I MECHANIKA SAMOCHODOWA BOGUSŁAW KACHEL, STRYSZAWA',
        'Blacharstwo, Lakiernictwo Mech. Poj. i Ciągników B. Zapała, Mniów',
        'Blukem team idea Tomasz Smoliński, Widawa',
        'Bonk Zbigniew, Starogard Gdański Rokocin',
        'Bulek Marcin Cybulski, Toruń',
        'CAR-TECH pro Andrzej Rogacewicz, ZIELONA GÓRA',
        'CARGUM S.C., Jelenia Góra',
        'CaRock, Dobromierz',
        'COL-TECH, Bychawa',
        'COM-Serwis Marcin Czarnik, Skrzyszów',
        'DaCar, Stargard',
        'DAKA, Oświęcim',
        'DAN-V.A.G. Daniel Mrzygłód, Nowa Wieś Lęborska',
        'DAR-MOT DARIUSZ KLUPA, SŁOMNIKI',
        'DARIUSZ GROCHOWSKI FIRMA USŁUGOWO-HANDLOWA "DADA"., Zabrze',
        'DD AUTO, Krzeszowice',
        'DIMPS Sebastian Szostak, Oborniki Śląskie',
        'Elektromechanika Kamil Grinholc, Kębłowo',
        'Elektromechanika Pojazdowa Artur Krzywański, CZęstochowa',
        'ELEKTROMECHANIKA POJAZDOWA ELDĄB DĄBROWSKI DANIEL, SZYPLISZKI',
        'Elektromechanika Samochodowa Andrzej Węgrzyn, Głubczyce',
        'Elektromechanika, Bielsk Podlaski',
        'Elektronika Samochodowa S.C., Przecław',
        'Emmax-serwis łukasz huk, Tuczempy',
        'EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ',
        'Extruckt sp. z o.o, warszawa',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, Ćwików',
        'F. H. U. BH-TRANS BARTOSZ HILA , ŁOSOSINA DOLNA',
        'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
        'F.H.AUTO-DAR, Bytom',
        'F.H.KOMA AUTO-SERWIS MIROSŁAW KOZA, RADZYŃ PODLASKI',
        'F.H.U SILENT-CAR Jerzy Pniak, kochanów',
        'F.H.U. "CAR-MAR" MARIUSZ KUMANOWICZ, KRAŚNIK',
        'F.H.U. AUTO NAPRAWA MAREK SMUTEK, Nowa Jastrząbka 48',
        'F.H.U. TAX KAROL FRĄK, DALESZYCE',
        'FHU BEMAR, SZADEK',
        'FHU BOBAK, GORLICE',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
        'FHU SZCZUREK GARAGE RAFAŁ SIERANKOWSKI, Zabrze',
        'FHU SZIK , Rzeszów',
        'Filip Buda Serwis transport, baRAnowo',
        'FIRMA auto market, pajęczno',
        'Firma Handlowo - Usługowa Łukasz Buciak, Bielany Wrocławskie',
        'firma usługowa Arkadiusz Ciskowski, Mrągowo',
        'Firma Usługowa Hubert Suchy, Gniezno',
        'FRANCE-SERWIS, JASTRZĘBNIKI',
        'GA-CAR WARSZTAT SAMOCHODOWY SZCZYREK GRZEGORZ, ROPCZYCE',
        'GP Auto Garage Przemysław Gąciarz, Gryfino',
        'hs auto, WRONKI',
        'Intertransport Sp. z o.o., Radzyń Podlaski',
        'J-TRANS Jacek Słomczyński, Warszawa',
        'JARMUS, Radom',
        'Jaro-Filtr, Radzymin',
        'JOTA Moto Centrum, Legnica',
        'Karol Czarnecki, Radom',
        'KG MOTORS KRYSTIAN KOWALCZYK JAKUB GIL SPÓŁKA CYWILNA, Warszawa',
        'Kilian AUTO-USŁUGI HANDEL KOMIS, Radomyśl wielki',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'kordiano, koziegłowy',
        'Krakpol, Milejów',
        'KT Serwis Konrad Tusiewicz, Marki',
        'M.A.S. Serwis Kamil Krzysztofik, Starogard Gdanski',
        'Mag-Serwis Jacek Wódkiewicz, Jednorożec',
        'MAGTOM, Czermin',
        'Maksman Garage, Stare Czarnowo ',
        'Mariusz Moskalik, Rymanów ',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'MECHANIKA POJAZDOWA ANDRZEJ PIETROWSKI, BORONÓW',
        'Mechanika pojazdowa andrzej Świętochowski, Gryfów Śląski',
        'Mechanika Pojazdowa EM-CAR, Zagnańsk',
        'Mechanika Pojazdowa Konrad Gołębiewski, Borowa Góra',
        'Mechanika pojazdowa korneluk, Gdynia',
        'MECHANIKA POJAZDOWA Krzysztof Hutek, Limanowa',
        'Mechanika Pojazdowa Marek Stryganek, Hajnówka',
        'Mechanika Pojazdowa Mariusz Kędziora, Głowno',
        'MECHANIKA POJAZDOWA SERWIS OPON ZBIGNIEW MIKOŁAJCZUK, WIJEWO',
        'MECHANIKA POJAZDOWA WEJ-KAS WEJNER PIOTR, chełm',
        'Mechanika Pojazdowa Zator Edward, Żagań',
        'Mechanika pojazdowa zbigniew szulc, Duszniki',
        'Mechanika Samochodowa Marcin Janicki, Dębica',
        'mechanika samochodowa, prudnik',
        'MICHAŁ BŁĄKAŁA AUTO-SERWIS, Bochnia',
        'MOB-DAN, Stawiszyn',
        'MOTOROLI Roland Kruszwic, Zduńska Wola',
        'MOTOSKLEP Sp. z o.o. Sp. k., Prabuty',
        'MT SERWIS Michał Traczyk , Piastów',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH GRZEGORZ ANDRUSZKIEWICZ, PASŁĘK',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'Naprawa Pojazdów Samochodowych, Pludry',
        'P.H.AUTO-KOR IRENEUSZ KORZENIOWSKI, DĘBNO',
        'P.H.GOLF A.ŁOBODA,H.ŁOBODA,Z.STREHLKE, Bydgoszcz',
        'P.U.H. TAD-ROM, MIKOŁÓW',
        'perfect auto filip kozłowski, białystok',
        'Phu Amakares Arkadiusz Marczuk, Szczecin',
        'PHU ASTRA, jaworzno',
        'PHU AUTO SERWIS MIROSLAW MARCISZONEK, Miastko',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'PHU MKA Andrzej Antkiewicz, Dąbie',
        'PHU MOTOGAMA JERZY CZYŻEWSKI, Grabówka',
        'PHU Paweł Kozłowski, Wojsławice',
        'Pilichowski Marcin, Pniewo',
        'Pio-Mar Auto Naprawa Piotr Stępień Marcin Rogala, Bełchatów',
        'Piotrowska Elżbieta , CHOJNA',
        'Plich Serwis Rafał Plich, Lubawka',
        'PPH BETROM ROMAN BETLEJEWSKI, OSTRÓW MAZOWIECKA',
        'PPUH MEGAT MACIEJ MICHALIK, BARLINEK',
        'Pro-car, Tanowo',
        'PRODIA MICHAŁ śWIDERSKI, Wyszków',
        'Prolen Mechanika Samochodowa Marcin Prokop , Bielawa',
        'Promottech sp.j Jachimowicz Borawski, Łomża',
        'Prusinowski Car Service, Godzieszów',
        'PUH ADAKO Mechanika Pojazdowa Bohdan Kocisz, Białystok',
        'PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski, Bydgoszcz',
        'PUH Motozbyt, Dąbrowa Tarnowska',
        'Punkt naprawy samochodów Mariusz Górny, Łobez',
        'RAD-TRANS, Bydgoszcz',
        'Rafał Piskorz Mechanika pojazdowa, Kraków',
        'RiM-SERVIS, Lębork',
        'Serwis Samochodowy ,,Auto Szymek Garage\'\', Stare Miasto',
        'Sprintex, Lipowa',
        'SPRZEDAŻ SAMOCHODÓW I CZĘŚCI IZABELA MINKIEWICZ, SUWAŁKI',
        'TIRCARS SP.J. MARIUSZ CHORĄŻY I WSPÓLNICY, ZALESIE',
        'TIRPARTNER, ŁUKÓW',
        'Tom-Car, Biała Podlaska',
        'TOMEX AUTOSERWIS TOMASZ KONATOWICZ, RADOM',
        'Turbo-Expert S.C. Jacek kokot, Artur arndt, Siemianowice Śląskie',
        'Usługi Motoryzacyjne Marcin Bołoz, Piwniczna-Zdrój',
        'V. 8. Aleksander Maliszewski, Głogów',
        'Warsztat mechaniczno blacharski, Suchy Las ',
        'WARSZTAT MECHANIKI I BLACHARSTWA POJAZDOWEGO STEFAN PUSZ, ZAGWIŹDZIE',
        'Warsztat Samochodowy Łukasz Dobrzański, Stargard',
        'Węgrzyn & Węgrzyn - Auto Serwis Węgrzyn S.J., Kraków',
        'WIST     KRZYSZTOF DRĄCZKOWSKI, Hrubieszów',
        'ZAKŁAD BRACI OPALA, KIELCE',
        'Zakład elektromechaniki i mechaniki pojazdowej Beata Gehfeld, Grudziądz',
        'Zakład Mechaniczny Łukasz Zdunek, Wojcieszków',
        'zakład mechaniczny wądołowski piotr, Zawady',
        'Zakład Mechaniki Pojazdowej Antoni Szubzda, Wasilków',
        'zakład mechaniki pojazdowej sylwester dworanowski, słupsk',
        'ZUH MOTO-BAZAR, RADOM',
      ]
    },
    {
      dataTab: '25.10-31.11',
      winnersList: [
        '"SEBO", PIEŃSK',
        '( Auto ) STANISŁAW KANAREK, Drawsko Pomorskie',
        '4QŁKA Piotr Szymański , Pisz',
        'AD SERWIS AGROBUD, MYŚLENICE',
        'ADIMECH, KĘPNO',
        'Aleksandra Sobolewska, RAWA MAZOWIECKA',
        'AMGT Ford, zabrze',
        'ASZ NAPRAWA POJAZDÓW, Sawin',
        'AUTO AGRO COMPLEX, OPOLE LUBELSKIE',
        'Auto Akces Shop Pacholska Ewelina, Inowrocław',
        'Auto Bączek Sp. z o.o. Sp. K., Tarnów',
        'Auto center serwis, Sokółka',
        'AUTO CENTRUM, Jaworzno',
        'AUTO CENTRUM, RADZIONKÓW',
        'AUTO EXPERT PIOTR ŚWIDER, SANOK',
        'AUTO GUCIO Paweł Gąsiorek, ŁĘGOWO',
        'AUTO KLINIKA  TOMASZ KULIK, DĘBLIN',
        'AUTO KLINIKA GRZEGORZ LUBOCKI, LĘBORK',
        'AUTO KLINIKA NOSARZEWSKI, sTARA IWICZNA',
        'AUTO MECHANIK SERWIS Tomasz Dudek, Rogów',
        'auto Mechanika, Frampol',
        'Auto Miś, Szczecin',
        'Auto naprawa Dariusz Swierzbin, Filipów',
        'Auto Naprawa Krzysztof Kroll, Ostrów Wielkopolski',
        'Auto Naprawa Łukasz Szmigiel, Mrągowo',
        'AUTO NAPRAWA MiB STARĘGA, SIEDLCE',
        'AUTO NAPRAWA, LIPIE GÓRY',
        'auto naprawa, suwałki',
        'AUTO SERVICE, PSZCZYNA',
        'AUTO SERVIS WOJCIECH NIEDZIELA, MIKOŁÓW',
        'AUTO SERWIS  SPEED-CARS , DĄBROWA GÓRNICZA',
        'AUTO SERWIS "KWIATEK", Jawor',
        'AUTO SERWIS ANDRZEJ ZBĄDZKI, Osiek',
        'auto serwis bednarski, iława',
        'AUTO SERWIS DAMIAN ŚWIADEK, GOLENIÓW',
        'Auto Serwis Idzikowski, Kielce',
        'Auto serwis Jakub Sarna, orzesze',
        'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
        'AUTO SERWIS TOMASZ SZAKOWSKI, siedlce',
        'AUTO SERWIS ZIELIŃSKI, Malbork',
        'AUTO-ALFA 1 GRZEGORZ HODUREK, SUŁKOWICE',
        'AUTO-COMPLEX , Morawica',
        'Auto-Części Patryk Łukomski, IŁAWA',
        'Auto-Gladi Zakład Mechaniki Pojazdowej, Stare Siołkowice',
        'Auto-Naprawa , Pilzno',
        'AUTO-NAPRAWA Radosław Nasierowski, Ciechanów',
        'AUTO-SERWIS  Michał Mazur, Bydgoszcz',
        'Auto-Serwis Pawlik, włoszczowa',
        'AUTO-SERWIS POGWARANCYJNE NAPRAWY SAMOCHODÓW JACEK KRZYŻEWSK, Kętrzyn',
        'AUTO-SERWIS ROMUALD SZWOCH, Tuchola',
        'auto-śmiech mechanika pojazdowa, krzemieniewo',
        'AUTO-TOP S.C., KROSNO',
        'Auto.A.S.serwiS Adam Szkaradek, Wolibórz',
        'AUTOCENTRUM MARCIN NAPORA, Kroczyce',
        'AUTODAS SP. Z O.O., Elbląg',
        'AUTOMARK MECHANIKA POJAZDOWA KRZYSZTOF MARKOWSKI, KARTUZY',
        'AUTOMAX Jacek Bagiński, kolobrzeg',
        'AUTOSCAN Grzegorz Bystroń, Sulejówek',
        'AUTOSERWIS GÓRALSKI, ŁÓDZ',
        'Autoset, Dąbrowa',
        'Autotech PUH s.c. J.T. Szukała, Poznań',
        'Beścars Paweł Kielar, Markowa',
        'Best Car Serwis, Kraków',
        'Big Johny garage, Skowarcz',
        'Blukem team idea Tomasz Smoliński, Widawa',
        'Bogdan Kowalczyk, Łyse',
        'Bosch Service S.C. I.K.Gruner, jastrzębie- zdrój',
        'CAR CRAFT SERVICE BASTEK SEBASTIAN JARNOT, Bieruń',
        'CAR SERVIS, Minoga',
        'CARO sp.j A.wysocki Z.Wysocki, TCZEW',
        'CaRock, Dobromierz',
        'COLDMARK, Łosice',
        'DaCar, Stargard',
        'decocar, ZEBRZYDOWICE',
        'DK SERWIS, Knurów',
        'DREAM CAR SEBASTIAN DUNIEC, PABIANICE',
        'Elektromechanika Kamil Grinholc, Kębłowo',
        'Elektromechanika Pojazdowa Artur Krzywański, CZęstochowa',
        'ELEKTROMECHANIKA POJAZDOWA ELDĄB DĄBROWSKI DANIEL, SZYPLISZKI',
        'elektromechanika pojazdowa Marek Ozga, Zarzecze',
        'ELEKTROMECHANIKA POJAZDOWA MICHAŁ NOWACKI, OSTRZESZÓW',
        'ELEKTROMECHANIKA POJAZDOWA, PLESZEW',
        'EMMAX MAGDALENA MAKSYLLEWICZ, BYSTRZYCA KŁODZKA',
        'Extruckt sp. z o.o, warszawa',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, Ćwików',
        'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
        'F.H.U  "Auto-hol" Piotr Urbaniak, Raba Wyżna',
        'F.H.U. auto-nis, Nisko',
        'F.H.U. ELEFANT MARCIN HOIM, Skrzyszow',
        'FHU "RD DYL" Rafał Dyl, Staszów',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
        'Fhu Damian Kalisiak, Wolin',
        'FHU SZIK , Rzeszów',
        'fhu vema zbigniew pamrów, MAŁKINIA GÓRNA',
        'Firma Handlowo - Usługowa Łukasz Buciak, Bielany Wrocławskie',
        'firma usługowa Arkadiusz Ciskowski, Mrągowo',
        'Fut-car serwis Piotr  Tryba, Łebunia ',
        'GAKO-Sklep Motoryzacyjny Gądek Konrad, Grębów',
        'GP Auto Garage Przemysław Gąciarz, Gryfino',
        'Intertransport Sp. z o.o., Radzyń Podlaski',
        'JARCAR1, Sędziszów Małopolski',
        'Jaro-Filtr, Radzymin',
        'JOTA Moto Centrum, Legnica',
        'Jul-car, Włocławek ',
        'KAMPOL KAMILA BIEDRZYCKA, NOWE MIASTO NAD PILICĄ',
        'Karol Czarnecki, Radom',
        'Kilian AUTO-USŁUGI HANDEL KOMIS, Radomyśl wielki',
        'KIM Mechanika Pojazdowa Fiedziuszko Krzysztof, Wejherowo',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'KOPEX, KIELCE',
        'KT Serwis Konrad Tusiewicz, Marki',
        'ŁUKASZ CZECH WARSZTAT SAMOCHODOWY MATEXCAR, MORAWICA',
        'M.A.S. Serwis Kamil Krzysztofik, Starogard Gdanski',
        'Magic Fast Lisik Mariusz, Oleśnica',
        'Maksman Garage, Stare Czarnowo ',
        'Mas-Car Baran Jarosław, Ostaszewo',
        'Mechanika - Elektryka Samochodowa Jakub Spendowski, Kudowa Zdrój',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'MECHANIKA POJAZDOWA , Suszec-Rudziczka',
        'MECHANIKA POJAZDOWA ADRIAN BAM, ĆWIKLICE',
        'Mechanika pojazdowa andrzej Świętochowski, Gryfów Śląski',
        'Mechanika Pojazdowa Dariusz Piesik, Gdynia',
        'Mechanika Pojazdowa EM-CAR, Zagnańsk',
        'Mechanika Pojazdowa Krzysztof Gruca, Chlewice',
        'MECHANIKA POJAZDOWA Krzysztof Hutek, Limanowa',
        'MECHANIKA POJAZDOWA LUDA HUBERT, ŁĄCZNIK',
        'Mechanika Pojazdowa Mariusz Huzior, Lubin',
        'Mechanika Pojazdowa Mariusz Kędziora, Głowno',
        'Mechanika Pojazdowa Mirosław Pielak, Górzno',
        'Mechanika pojazdowa Ryszard Łuczak, Kościan',
        'MECHANIKA POJAZDOWA SERWIS OPON ZBIGNIEW MIKOŁAJCZUK, WIJEWO',
        'Mechanika Pojazdowa Tomasz Domański, Bełchatów',
        'mechanika pojazdowa tomasz namysło, ŻYWOCICE',
        'Mechanika Samochodowa Marcin Janicki, Dębica',
        'MECHANIKA SAMOCHODOWA UKŁADY WYDECHOWE ARTUR BAGROWS, Łęczyca',
        'mechanika samochodowa, prudnik',
        'Mechanika, Jodłownik',
        'Merely, Piława Dolna',
        'Merfor Beata Nowak, Toruń',
        'MK SERWIS Marcin Kowal, Wola Suchożebska',
        'MN PARTS, Siemianowice Śląskie',
        'Mobile-Car Rafał Bednarz, Zaleszany',
        'Moto-Auto CS, Wysokie Mazowieckie',
        'Moto-Car, Kępno',
        'MOTO-TECH, Piaski',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'NyGoManiak, Kołobrzeg',
        'OK. SERWIS Mechanika Pojazdowa, Kupientyn',
        'OPLEX, Łódź',
        'P. H. U. Magdalena Urban , Chodel',
        'P.H.U HIMML, Racibórz',
        'P.U.H. TAD-ROM, MIKOŁÓW',
        'P&C Ciborski Auto Serwis, Jeziorany',
        'Paweł Staciwa AUTO-KRZYŚ Mechanika Pojazdowa, Radoszyce',
        'Paweł Szpara, Mirów Stary',
        'Peter, Mircze',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'PHU GRZEŚ-MOT, SZYDŁOWIEC',
        'PHU MKA Andrzej Antkiewicz, Dąbie',
        'PHU Paweł Kozłowski, Wojsławice',
        'Piotrowska Elżbieta , CHOJNA',
        'Plich Serwis Rafał Plich, Lubawka',
        'PPHU MAX Gabriela Krawczyk, Krzepice',
        'Pphu Moto Kord, gorzów wielkopolski',
        'PPHU TOMAR TOMASZ NOWAK, TUSZYN',
        'pphut carmar, sokołow podlaski',
        'PPUH MEGAT MACIEJ MICHALIK, BARLINEK',
        'PRODIA MICHAŁ śWIDERSKI, Wyszków',
        'PRZEDSIĘBIORSTWO HANDLOWO-USŁUGOWE "WULKANEX" KRZYSZTOF STAPIŃSKI , WRÓBLIK SZLACHECKI',
        'Przemysław Pietrzak, Biała',
        'PUH ADAKO Mechanika Pojazdowa Bohdan Kocisz, Białystok',
        'PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski, Bydgoszcz',
        'Punkt naprawy samochodów Mariusz Górny, Łobez',
        'Q Service HŁADKA, Gorzow Wlkp',
        'Rafał Piskorz Mechanika pojazdowa, Kraków',
        'Rakautoserwis, Warszawa',
        'RondoCar, Lubartów',
        'RS SERWIS ROBERT SIKORSKI, ALEKSANDRÓW ŁÓDZKI',
        'RYNGWELSCY s.c. Marek - Janusz Ryngwelscy, Człuchów',
        'serwis mobilny mariusz mechanik mariusz klewar, Gliwice',
        'SPRINGER , Łomża',
        'Techniczna Obsługa Samochodów TOS-SERWIS s.c., Zławieś Wielka',
        'TEK TRANS, CZERNICHÓW',
        'TIRPARTNER, ŁUKÓW',
        'TOMCAR, Czmoń',
        'TRANS PETRO COLOR , Piaseczno',
        'TRUCK-SERVICE , Szczytno ',
        'Usługi Motoryzacyjne Marcin Bołoz, Piwniczna-Zdrój',
        'UWEX, Paszyn',
        'V. 8. Aleksander Maliszewski, Głogów',
        'Volvomania, Katowice',
        'WIST     KRZYSZTOF DRĄCZKOWSKI, Hrubieszów',
        'ZAKŁAD BRACI OPALA, KIELCE',
        'Zakład mechaniki pojazdowej i wulkanizacji Auto-mati Edward Sulewski , Ełk',
        'Zakład mechaniki pojazdowej i wulkanizacji, Dzianisz',
        'ZAKŁAD MECHANIKI POJAZDOWEJ, ŁĘCZYCA',
        'ZAKŁAD USŁUGOWO MECHANICZNY & DERBA DANIEL, przemyśl',
        'Zbigniew Szydlak, Konin',
        'ZMUDA SERWIS MARIUSZ ZMUDA, Nakło koło Opola',
        'ZUH MOTO-BAZAR, RADOM',
      ]
    },
    {
      dataTab: '01.11-07.11',
      winnersList: [
        '( Auto ) STANISŁAW KANAREK, Drawsko Pomorskie',
        'A.S.C. USŁUGI MOTORYZACYJNE ARTUR MALON, OSTROŁEKA',
        'ADMAR - MECHANIKA POJAZDOWA MARCIN SZWARC, Gorzów Wlkp',
        'AMS SERWIS ARTYMOWICZ MICHAŁ, OLSZTYN',
        'ANTER S.C., PANIÓWKI',
        'AP-Auto Usługi Wielobranżowe , Stare Bielice ',
        'Arkadiusz Orzechowski Zakład Mechaniki Pojazdowej, Chorzele',
        'Armar Auto-Serwis, Kraków',
        'AUGUSTYNIAK SERWIS, Pruszków',
        'Auto -Kowal, Górowo Iławeckie',
        'AUTO CENTRUM, RADZIONKÓW',
        'Auto DEMS, Sorkwity',
        'AUTO GUCIO Paweł Gąsiorek, ŁĘGOWO',
        'AUTO KLINIKA NOSARZEWSKI, sTARA IWICZNA',
        'Auto Matunin Spółka z o.o., Wrocław',
        'AUTO MAX Zdzisław Trotzki, Orle',
        'AUTO MECHANIK SERWIS Tomasz Dudek, Rogów',
        'AUTO MECHANIKA "U MAGIKA", ZGORZELEC',
        'auto Mechanika, Frampol',
        'Auto Miś, Szczecin',
        'AUTO MOTO SERWIS , NYSA',
        'Auto Naprawa Krzysztof Kroll, Ostrów Wielkopolski',
        'Auto Naprawa Lipniewski Piotr, chełmno',
        'Auto Naprawa Łukasz Szmigiel, Mrągowo',
        'Auto Naprawa Tadeusz Śmiarowski, Łomża',
        'Auto Naprawa Zakład Uslugowo-Handlowy Krzysztof Jasiński, Sianów',
        'AUTO PERFEKT RALLY, LUBLIN',
        'AUTO SERVICE, Ząbrowo ',
        'Auto Serwis Borek, Wodzisław Ślaski',
        'AUTO SERWIS DAMIAN ŚWIADEK, GOLENIÓW',
        'Auto serwis kopel, Pszów',
        'Auto Serwis Mechanika-Wulkanizacja Mariusz Ołdak, Warszawa',
        'AUTO SERWIS PERFEKT LUCYNA KACZMAREK, WEJHEROWO',
        'auto serwis vip korycki marcin, radom',
        'AUTO-ALFA 1 GRZEGORZ HODUREK, SUŁKOWICE',
        'Auto-color DC, Dydnia',
        'AUTO-EXPERT, Małomice',
        'auto-kala, Przywory',
        'AUTO-MARK P.P.H.U. KONRAD MŁUDZIK, ŁÓDŹ',
        'Auto-Naprawa , Pilzno',
        'AUTO-NAPRAWA Radosław Nasierowski, Ciechanów',
        'Auto-naprawa, Małkinia Górna',
        'AUTO-RAD-SERVICE, Lubowidz',
        'AUTO-RAF SOBERA RAFAŁ, PĄTNÓW',
        'AUTO-SERVICE MGR INŻ. MICHAŁ ŚWIETLIKOWSKI, WARSZAWA',
        'AUTO-SERWIS KOŁODZIEJCZYK, CZESTOCHOWA',
        'AUTO-SERWIS ROMUALD SZWOCH, Tuchola',
        'AUTO-SERWIS TOMASZ GONDEK, STALOWA WOLA',
        'Auto-Tech Witold Wilk, Rzeszów',
        'AUTO-TECH, Miedzyrzec Podlaski',
        'AUTO-TOP S.C., KROSNO',
        'Autocomplexe Adam Błoński, Zagnańsk',
        'AUTOFORD Z.M.P., NOWA SÓL',
        'AUTOM, KLEOSIN',
        'AUTOMAX Jacek Bagiński, kolobrzeg',
        'Automet Krzysztof Szymczak, Tarnowskie Gory',
        'AUTOMOBIL SERWIS DIAGNOSTYKA I NAPRAWA SAMOCHODÓW, Jarosław',
        'Automobilklub Podlaski, Białystok',
        'Autoserwis Wiking, Szczecin',
        'BEST PARTS, Łódź',
        'BMCAR Adrian Gołoś, WĘGRÓW',
        'BOMAG-AUTO-MASTER Mariusz Golberg, JEDLNIA-LETNISKO',
        'BOSCH SERVICE AUTO CELEJ , Łuków',
        'car serwis Bartłomiej szczerbak, Rudawa',
        'CAR-TECH, Wadowice',
        'CARFIX Adam Tomczyk, Piła',
        'CaRock, Dobromierz',
        'Carus.pl Sp. z o.o., Łódź',
        'Centrum Samochodowe Tomasz Mroczkowski , Gorzów Wielkopolski ',
        'CENTRUM SERWISOWE K.KARPOWICZ S.J., WARSZAWA',
        'DAKA, Oświęcim',
        'DAR-MOT DARIUSZ KLUPA, SŁOMNIKI',
        'DERASERWIS, MYSŁOWICE',
        'Eldorado Krzysztof Piergies, Nowogrodziec',
        'Elektromechanika Pojazdowa Artur Krzywański, CZęstochowa',
        'elektromechanika pojazdowa Marek Ozga, Zarzecze',
        'Emmax-serwis łukasz huk, Tuczempy',
        'EUROTIM EDWARD NIEDBALSKI, BYDGOSZCZ',
        'Extruckt sp. z o.o, warszawa',
        'F. H. U.  "ADMAX" CZĘŚCI SAMOCHODOWE ADAM ZACHARA, Ćwików',
        'F.H. MIX USŁUGI-HANDEL -PRODUKCJA, DĄBROWA TARNOWSKA',
        'F.H.U Marek Mrugała, Poronin',
        'F.H.U. AUTO GIGANT TOMASZ STOLARSKI, KRAKÓW',
        'Fast-Serws Damian Piekarz Mechanika Pojazdowa, Tarnobrzeg',
        'FBU PIOTR PEJKO, PILZNO',
        'FHU "RD DYL" Rafał Dyl, Staszów',
        'FHU BEMAR, SZADEK',
        'FHU CARSERVICE PIOTR RATAJCZYK, KAMIENNA GÓRA',
        'FHU POLCAR, PAWEL MARZEC, Ustrzyki Dolne',
        'FHU SZIK , Rzeszów',
        'FHU Tomasz Nakoneczny, Goleniów',
        'firma usługowa Arkadiusz Ciskowski, Mrągowo',
        'Firma Usługowa Auto Naprawa, Grabica',
        'Firma Usługowa Auto-Nowak Andrzej Nowak, Mogilno',
        'Fut-car serwis Piotr  Tryba, Łebunia ',
        'GP Auto Garage Przemysław Gąciarz, Gryfino',
        'INTERAUTO, TOMASZÓW LUBELSKI',
        'Interpart Maciej Drozdowski, Sandomierz',
        'Intertransport Sp. z o.o., Radzyń Podlaski',
        'Janusz Maciejewski, GRUDZIADZ',
        'Jaro-Filtr, Radzymin',
        'Kacper Rybczyński, Zgierz',
        'KAMPOL KAMILA BIEDRZYCKA, NOWE MIASTO NAD PILICĄ',
        'KG MOTORS KRYSTIAN KOWALCZYK JAKUB GIL SPÓŁKA CYWILNA, Warszawa',
        'Kilian AUTO-USŁUGI HANDEL KOMIS, Radomyśl wielki',
        'KOŁODYŃSKI GARAŻ, STRZEGOM',
        'KOMIS SAMOCHODOWY GOLDCAR MARZANNA MORITZ  , Łuków',
        'KT Serwis Konrad Tusiewicz, Marki',
        'Largo Usługi Samochodowe, Czerwionka Leszczyny',
        'M.S. SERVICE, Kraków',
        'Magic Fast Lisik Mariusz, Oleśnica',
        'MAKO Maciej Kotyza, Kraków',
        'Maksman Garage, Stare Czarnowo ',
        'Mar-Auto, Grabowska huta',
        'MECHANIKA I BLACHARSTWO POJAZDOWE BRZOZA JAROSŁAW, KĘDZIERZRZYN-KOZLE',
        'MECHANIKA POJAZDÓW SAMOCHODOWYCH MARCIN ŚMIGIEL, BLIZNE',
        'MECHANIKA POJAZDOWA "WIPER" Jarosław Walczuk, Końskie',
        'Mechanika pojazdowa andrzej Świętochowski, Gryfów Śląski',
        'Mechanika Pojazdowa Blacharstwo i Lakiernictwo, Lisewo',
        'Mechanika Pojazdowa EM-CAR, Zagnańsk',
        'Mechanika Pojazdowa Handel Częściami i Transport W Szymański, Stawiszyn',
        'MECHANIKA POJAZDOWA inż. Jarosław Nowaczyk, WĄSOSZ',
        'Mechanika Pojazdowa Jarosław Ciarkowski, Nowy Dwór Gdański',
        'mechanika pojazdowa Krapkowski Kazimierz, Nowy Barkoczyn',
        'Mechanika Pojazdowa Krzysztof Gruca, Chlewice',
        'Mechanika Pojazdowa Krzysztof Tabor, Stalowa Wola',
        'Mechanika Pojazdowa Marek Stryganek, Hajnówka',
        'mechanika pojazdowa święty rafał, Łańce',
        'Mechanika Pojazdowa Tomasz Nowak, Stęszew',
        'mechanika pojazdowa Witczak &amp; cybulski, wrzosowo',
        'mechanika pojazdowa, duszniki',
        'Mechanika Pojazdowa, Hrubieszów',
        'MECHANIKA SAMOCHODOWA CZESŁAW CZYRNEK, Tymbark',
        'medard-auto@o2.pl, parczew',
        'Mega Service Radosław Dąbrowski, ostrołeka',
        'Merely, Piława Dolna',
        'Michał Kociołek, Żywiec',
        'MiCo Car Michał Kotarski, Raczki',
        'MKT Monika Kafel, Katowice',
        'Mobile-Car Rafał Bednarz, Zaleszany',
        'MOBILNY SERWIS AGRO-TIR ADAM DANIŁOWICZ, Szypliszki',
        'monfils, opacz kolonia',
        'Moto Lux , OLSZTYN',
        'MOTO-FAN S.C., PRZEMYŚL',
        'Motocombi, Radziszów',
        'MotoGrześ, Praszka',
        'Motomik, wrocław',
        'motor-serwis, kraśnik',
        'MS-GARAGE , Łòdź',
        'NAPRAWA POJAZDÓW SAMOCHODOWYCH, LUBLINIEC',
        'Naprawa samochodów, Tuliłów',
        'P H U DARKO DARIUSZ OSADNIK, SKOMLIN',
        'P. H. U. DYMEX  ZBIGNIEW MIELEWCZYK, KOŚCIERZYNA',
        'P.H.U. AUTO-SERWIS MAROSZEK DAMIAN MAROSZEK, KANIÓW',
        'P.U-H. "AUTO-KULA" RYSZARD KULA, RĘDZINY',
        'P.U.H. TAD-ROM, MIKOŁÓW',
        'PACO-SERWIS Dawid Pakowski, Tomaszow Mazowiecki',
        'PAJERSKI WOJCIECH MECHANIKA POJAZDOWA KONSERWACJA PODWOZI SAMOCHODOWYCH, Nowy Targ',
        'PHU "HAITER", Pyskowice',
        'PHU AUTO CENTRUM "PAWŁOWSKI", BYTÓW',
        'PHU AUTO SERWIS WIKTOR UŁANOWSKI TOMASZ UŁANOWSKI, BRZEZINY',
        'PHU MKA Andrzej Antkiewicz, Dąbie',
        'PHU Wolnik Mirosława Wolnik, Rębiechowo',
        'PHU ZELMOT SP.J. EWELINA LASKOWSKA, ZBIGNIEW LASKOWSKI, ELBLĄG',
        'Piotr Penczek Car Club Warsztat Samochodowy, Chełm Śląski ',
        'PIT STOP Auto Serwis, Lwówek Śląski',
        'Podlaski Zakład Motoryzacyjny Sp. z o.o., Biała Podlaska',
        'PPH Polmarmex, Klecza Dolna',
        'PPHU BART-POL, Pakosław',
        'PPHU MIKOŁAJCZYK, Pogorzela',
        'PPUH MEGAT MACIEJ MICHALIK, BARLINEK',
        'PRODIA MICHAŁ śWIDERSKI, Wyszków',
        'Przemysław Bodych, Warszawa',
        'PUH Auto-Naprawa-Ostrowski Mariusz Ostrowski, Bydgoszcz',
        'PUH POLMOZBYT SO, BOLESŁAWIEC',
        'PUMIH PIOTR SZYMAŃSKI, GRODZISK MAZOWIECKI',
        'Punkt naprawy samochodów Mariusz Górny, Łobez',
        'RAD-TRANS, Bydgoszcz',
        'Rafał Piskorz Mechanika pojazdowa, Kraków',
        'REWEX, Legnica ',
        'Serwis Miczurin Beata Warta, Słupca',
        'serwis mobilny mariusz mechanik mariusz klewar, Gliwice',
        'smolka serwis, Wola Filipowska',
        'Steblej Car Service, Stronie Śląskie',
        'T&M SERWIS, Chorzów',
        'TAKS-CAR, WARSZAWA',
        'tdi-vag serwis, Żurawica',
        'Team-Car, Odrzykoń',
        'TIRPARTNER, ŁUKÓW',
        'Tomasz Szymenderski, Kostrzyn nad Odrą',
        'u przema, tomaszów maz',
        'USŁUGI MECHANICZNE, DIAGNOSTYCZNE I SPAWALNICZE, TARNAWATKA',
        'Uslugi motoryzacyjne Przemysław snakowski, Bielsko Biała',
        'VRSERVICE Wojciech Borysiuk, Choroszcz',
        'Warsztat Samochodowy, Strzebielino',
        'Zakład Mechaniczny Mobilny Dariusz Łojko, Siemiatycze',
        'zakład mechaniki pojazdowej mgr inż. zbigniew białas, opoczno',
        'zakład mechaniki pojazdowej, Bielsk Podlaski',
        'Zuh Autos baj spółka jawna, DREZDENKO',
        'ZUH MOTO-BAZAR, RADOM',
      ]
    },
  ]

  return (
    <StartWrapper id="Start">
      <StartContainer>
        <StartRow>
          <StartColPoints>
            <StartPointsWrapper>
              <Img fluid={data.StartPoints.childImageSharp.fluid} />
              {winners &&
                winners.slice(1, winners.length).map((_, index) => {
                  return (
                    <StartPoint
                      key={index}
                      stage={index + 1}
                      href="#listWinners"
                      to="listWinners"
                      offset={-50}
                      onClick={() => setStagePoint(index + 1)}
                      smooth
                    />
                  )
                })}
              <StartPoint
                stage={11}
                href="#awards"
                to="awards"
                offset={-100}
                smooth
              />
            </StartPointsWrapper>
          </StartColPoints>
        </StartRow>
        <StartRowlWinnersList id="listWinners">
          <StartColWinnersTabs>
            <StartRow>
              {winners.map((winner, index) => {
                return (
                  <StartColWinnersTab
                    stage={index}
                    currentStage={stage}
                    key={index}
                    onClick={() => setStagePoint(index)}
                  >
                    <span>
                      {index > 0
                        ? `Etap ${index} (${winner.dataTab})`
                        : `${winner.dataTab}`}
                    </span>
                  </StartColWinnersTab>
                )
              })}
            </StartRow>
          </StartColWinnersTabs>
          {/*stage === 0 && <>
            {winners[stage].winnersLists.map((winner, index) => {
              return (
                <React.Fragment key={index}>
                  <StartColWinnersHeader as="h2">
                    {winner.header}
                  </StartColWinnersHeader>
                  <StartWinnersListItem winners={winner} stage={0} order={1} />
                  <StartWinnersListItem winners={winner} stage={0} order={2} />
                </React.Fragment>
              )
            })}

            <MotivationP>Rywalizacja trwa. Każdy może walczyć o główną nagrodę!</MotivationP>
          </>*/}

          {stage >= 1 && (
            <>
              <StartWinnersListItem winners={winners} stage={stage} order={1} />
              <StartWinnersListItem winners={winners} stage={stage} order={2} />
            </>
          )}
        </StartRowlWinnersList>
      </StartContainer>
    </StartWrapper>
  )
}

export default Start
